
import { Col, Container,Dropdown, Image, Nav, Navbar, Row } from "react-bootstrap"
import logo_blanco from '../../Style/Img/logo_blanco.svg'
import  "../../Style/Css/Header.css";
import {  useEffect, useState } from "react";
import { countryFlagName } from "../../helpers/countryInfo";
import {Link,NavLink, useHistory}  from "react-router-dom";
import UserAccount from '../../Style/Img/Header/Cuenta.svg';
import CarritoBlanco from '../../Style/Img/Header/CarritoBlanco.svg';
import { ReactSVG } from "react-svg";
import { getCurrentUserId, LogOut } from "../../helpers/firebaseHelper";
import ExitIcon from '../../Style/Img/Header/ExitIcon.png';
import {clearCookies} from '../../helpers/functionsHelpers';
import {getCurrentUsername} from '../../helpers/firebaseHelper'
const countries = [
    { code: 'BO', title: 'Bolivia'},
    { code: 'SV', title: 'El Salvador'},
    { code: 'GT', title: 'Guatemala'}
  ];

export const HeaderBenefy=({countryEvent,Initialcountry,user})=>{
  
   const [expanded, setExpanded] = useState(false);
   const [toggleContents, setToggleContents] = useState(<><img alt="Pais" style={{width: "30px"}} src={countryFlagName(Initialcountry).icon}/> {countryFlagName(Initialcountry).name} </>);
   const history=useHistory();
   const [actualCountry, setActualCountry] = useState(Initialcountry)
   useEffect(() => {
    function handleClickOutside(event) {
        setTimeout(() => {
            if(expanded) {
                setExpanded(false);
            }
        }, 100)
    }
    console.log(`name s${getCurrentUsername()}`);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, [expanded]);
    const logOutEvent=()=>{
        clearCookies();
        LogOut();
        history.push("/JoinToBenefy")
    }
		return (
            <Container fluid className="p-0 " >
               { getCurrentUserId() && <Row>
                    <Col align="right">
                        <p className="linkTextHeader HeadermenuItems">Bienvenid@ {localStorage.getItem("Nameus")} </p>
                    </Col>
                </Row>}
                <Row className="mr-0" >
                    <Col lg="12" align="right" >
                    <Dropdown
                    className="mr-0 mr-md-1 mr-lg-1 "
                            onSelect={eventKey => {
                                setToggleContents(<><img alt={countryFlagName(eventKey).name} style={{width: "30px"}} src={countryFlagName(eventKey).icon}/> {countryFlagName(eventKey).name} </>);                                                         
                               countryEvent(eventKey);   
                               setActualCountry(eventKey);                           
                            }}
                            >
                            <Dropdown.Toggle variant="secondary" id="dropdown-flags" className="text-right" style={{ width: 150,background:"none",border:"none" }}>
                                {toggleContents}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {countries.map(({ code, title }) => (
                                    <Dropdown.Item key={code} eventKey={code}><img alt={title} style={{width: "30px"}} src={countryFlagName(code).icon}/> {title}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                    </Dropdown>
   
                    </Col>
                </Row>
                <Row  className="mr-0">
                    <Col lg="12" >
                            <Navbar  className="pb-5 " expand="lg" style={{ width: "100%" }}
                            expanded={expanded}
                            >
                                <Navbar.Brand  href="/">
                                     
                                        <Image  src={logo_blanco}
                                            style={{
                                                width:"145px"
                                            }}
                                            className="ml-md-5"
                                            />
                                        
                                       
                            </Navbar.Brand>
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" 
                                        onClick={() => setExpanded(!expanded)}
                                    />
                                    <Navbar.Collapse id="basic-navbar-nav">
                                        <Container fluid className="d-flex justify-content-end p-0">
                                    
                                        <Row >
                                        <Col >
                                        <Nav className="mr-auto float-right mr-5 mt-lg-0 mt-md-0  mt-5" >
                                          
                                             <NavLink to="/Articles"  className="linkTextHeader pt-2  HeadermenuItems text-center my-md-0 my-lg-0 my-3" activeClassName='is-active'>
                                                Articulos
                                            </NavLink> 
                                            <NavLink to="/GiftCards"  className="linkTextHeader pt-2 HeadermenuItems text-center my-md-0 my-lg-0 my-3" activeClassName='is-active'>
                                                 Gift Cards
                                            </NavLink>   
                                            <NavLink to="/WhoWeArePage"  className="linkTextHeader pt-2 HeadermenuItems text-center my-md-0 my-lg-0 my-3" activeClassName='is-active'>
                                                Quienes somos
                                            </NavLink>
                                            <NavLink to="/OurProductsPage"  className="linkTextHeader pt-2 HeadermenuItems text-center my-md-0 my-lg-0 my-3" activeClassName='is-active'>
                                                Nuestros productos
                                            </NavLink>
                                            <NavLink to="/DownloadPage"  className="linkTextHeader pt-2 HeadermenuItems text-center my-md-0 my-lg-0 my-3" activeClassName='is-active'>
                                                Descarga
                                            </NavLink>
                                            <NavLink to="/ContactPage"  className="linkTextHeader pt-2 HeadermenuItems text-center my-md-0 my-lg-0 my-3" activeClassName='is-active'>
                                                Contacto
                                            </NavLink>
                                            <Container className="d-inline text-right  p-0 my-md-0 my-lg-0 my-3 HeadermenuItems" style={{width:"auto"}}>
                                                <Col md="auto" className="CommerceOptionsHeader d-inline-block">
                                                    <Row className="mx-auto">
                                                        <Col className="mx-auto pt-0 m-0">
                                                            <p className="ml-2 mx-lg-auto mx-md-auto p-0 m-0 text-md-center text-left  linkTextHeader" >Comercios</p>
                                                        </Col>   
                                                    </Row>
                                                    <Row>
                                                        <Link to={{ pathname:"https://corporate.beneficioslatam.com/"}} target="_blank"   className="border pt-0 pb-0 pl-1 pr-1 d-inline linkTextHeader" >Entrar</Link>            
                                                        <Link to={{ pathname:"https://corporate.beneficioslatam.com/Grupos/Registro"}} target="_blank"  className="border pt-0 pb-0 pl-1 pr-1 m-0" style={{backgroundColor:'white'}}><span style={{color:"orange"}}>Registrarse</span></Link>              
                                                    </Row>
                                            </Col>
                                        </Container>
                                        {
                                            !user?( <NavLink to="/JoinToBenefy"  className="linkTextHeader pt-2  text-center my-md-0 my-lg-0 my-3" activeClassName='is-active'>
                                                    <ReactSVG className=" text-center"  beforeInjection={(svg) => {
                                                        svg.setAttribute('style', 'width: 1.7rem;padding-bottom: 0.5rem;')
                                                    }}  src={UserAccount} />  
                                                
                                                </NavLink>  )
                                           :
                                                (
                                                    <>
                                                    
                                                        <NavLink to="/CheckoutOrder"  className="linkTextHeader pt-2  text-center my-md-0 my-lg-0 my-3 " activeClassName='is-active'>
                                                           
                                                            <ReactSVG className=" text-center"  beforeInjection={(svg) => {
                                                                svg.setAttribute('style', 'width: 1.9rem;padding-bottom: 0.5rem;')
                                                            }}  src={CarritoBlanco} />  
                                                            
                                                        </NavLink>
                                             {    JSON.parse(localStorage.getItem(`cart${actualCountry}`) || "[]").length>0 ?   <span className='badge badge-warning d-inline-block mr-0 mr-lg-4 mr-md-4' id='lblCartCount'> {JSON.parse(localStorage.getItem(`cart${actualCountry}`) || "[]").length} </span>
                                                         :
                                                         <span className='d-inline-block mr-0 mr-lg-4 mr-md-4' > </span>           }
                                                        <a onClick={()=>logOutEvent()}  className="linkTextHeader pt-2  text-center my-md-0 my-lg-0 my-3" activeClassName='is-active'>
                                                            
                                                                    <img src={ExitIcon} alt="Salir"  style={{width:"2rem"}} title="Salir"/>
                                                        </a> 
                                                      </>
                                                )
                                        }
                                      
                                          
                                        </Nav>
                                        </Col>
                                    
                                        </Row>
                                        </Container>
                                    
                                    </Navbar.Collapse>
                                </Navbar>
		
                    </Col>
                    
                </Row>
            </Container>
		)
	}

