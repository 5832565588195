import React from 'react'
import '../../Style/Css/BenefyInputs.css' 

export const BenefyInput = (props) => {
    const justifySide=props.justifySide || "center";
    return (
        <div className={`d-flex justify-content-${justifySide}`}>
            <div className="group">      
                <input type={props.type}  defaultValue={props.value} onChange={e=>props.onchangeFunction(e.target.value)} required className="inputBenefy"/>
                <span style={{color: "red"}}></span>
                <span className="highlight"></span>
                <span className="bar"></span>
                <label className="labelInputBenefy">{props.title}</label>
        </div>
        </div>
       
    )
}
