import React, { createContext, useState } from 'react'

export const UIContext =createContext();

export const UiProvider= ({children}) => {
    const[Loading,setLoading]=useState(false)

    const showLoading=()=>{
        setLoading(true);
    }

    const hideLoading=()=>{
        setLoading(false);
    }

    return (
        <UIContext.Provider value={{
            Loading,
            showLoading,
            hideLoading
            
        }}>
            {children}
        </UIContext.Provider>
    )
}
