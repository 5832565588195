import { createContext, useState } from "react";

export const CountryContext=createContext();

export const CountryProvider=({children})=>{
    const [country, setCountry] = useState("SV");

    const newCountry=(NCountry)=>{
        setCountry(NCountry);
    }
    return(
        <CountryContext.Provider value={
           { country,
            newCountry}
        }>
            {children}
        </CountryContext.Provider>
    );
}