import React from 'react';
import { Slide } from 'react-awesome-reveal';
import { Col, Container, Row } from 'react-bootstrap';
import emptyBox from '../../Style/Img/emptyBox.png';
const textTitle={
    fontSize:"2rem"
}
const EmptySection = ({textMessage}) => {
    return (
        <Container>
        <Row>
            <Col   lg="12" align="center">
                <Slide>               
                    <p style={textTitle}>{textMessage || "Nuevos beneficios muy pronto."}</p>    
                    <img alt="Error Benefy" width="150" src={emptyBox}/> 
                </Slide>
            </Col>
        </Row>
        </Container>
    )
}
export default EmptySection
