import { getCurrentUserId } from "../helpers/firebaseHelper";
import { urlRequest } from "../helpers/urlRequest";

export const setTokenUser=async()=>{
    const a=urlRequest()
    const url=`${a.urlMicroSecurity}AppMovil/Authenticate`;
    let error,resp;
    const requestData={
        "userId":getCurrentUserId()
    }
   
    try{  
        var options={
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'x-api-key': '75APPMA1-B4DF-475E-A331-F737424F013C',
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
              body: JSON.stringify(requestData) // body data type must match "Content-Type" header
          }
        const res = await fetch(url, options);
        const json = await res.json();
        console.log(resp);
        debugger;
        localStorage.setItem('xAccessmb',json.data);
    } catch (error){
        error="Error al conectarse al servidor";
   
    }
    return{
        resp,
        error
    };
}