import React from 'react'
import '../../Style/Css/Footer.css'
import { Col, Container, Nav, Row } from 'react-bootstrap'
import { EmailIcon } from '../Icons/EmailIcon'
import { PhoneIcon } from '../Icons/PhoneIcon'
import { BenefyIcon } from '../Icons/BenefyIcon';
import FacebookIcon  from '../../Style/Img/Social/Facebook.svg';
import LinkedinIcon  from '../../Style/Img/Social/Linkedin.svg';
import  YoutubeIcon from '../../Style/Img/Social/Youtube.svg';
import  InstagramIcon  from '../../Style/Img/Social/Instagram.svg';
import { urlRequest } from '../../helpers/urlRequest';
import { useFetch } from '../../Hooks/useFetch';
import { useLoading } from '../../Hooks/useLoading';
import {NavLink}  from "react-router-dom";
import { fourthGray, grayPrimary, principalOrange } from '../../Style/Colors';


const colorGrayFooter={
    color:fourthGray
}


const orangeText={
color:principalOrange ,
fontSize:"1.1rem"
}
export const FooterBenefy = React.memo(({country}) => {
    let facebook,instagram,linkedin,youtube,phone,mail;
    
    const{urlCommerceMicro}=urlRequest();
    let body={"country":country}
    const {response, error, isLoading }=useFetch(`${urlCommerceMicro}Country/GetCountries`,
    {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
          body: JSON.stringify(body) // body data type must match "Content-Type" header
      },country
    );
        useLoading(isLoading);
    
        if(response && !error){          
            if(response.code===200){ 
                phone=response.data.phone;
                mail=response.data.email;
                instagram=response.data.instagram;
                linkedin=response.data.linkedin;
                youtube=response.data.youtube;
                facebook=response.data.facebook;             
             }          
        }
    
    return (
            <Container fluid  className="  mt-4 mx-0">
                <Row className="justify-content-center ml-0">
                    <Col lg="auto" className="px-0 ml-2 mb-4 mb-md-0 mb-lg-0 ">
                    <div className="pb-2">
                     <h3 style={{fontSize:"1.3rem",color:grayPrimary}}>CONTÁCTANOS</h3>
                    </div> 
                    <div>
                    <PhoneIcon ClassName="d-inline"  Width="15"/> <p className="d-inline" style={{...colorGrayFooter,fontSize:"15px"}}>{phone}</p>                 
                     </div>
                    <div className="pt-2">
                    <EmailIcon  ClassName="d-inline" Width="15"/> <p className="d-inline" style={{...colorGrayFooter,fontSize:"15px"}}>{mail}</p>                 
                     </div>
                    </Col>
                    <Col lg="auto">
                    <Nav className="pl-sm-0  pl-md-5 FooterMarginItemsMenu" >
                                            <NavLink to="/Articles"  style={orangeText}  className="mr-4 FooterMenuOptions" activeClassName='is-active'>                                                
                                                 Articulos
                                            </NavLink> 
                                            <NavLink to="/GiftCards"  style={orangeText} className="mr-4 FooterMenuOptions" activeClassName='is-active'>
                                                 Gift Cards
                                            </NavLink>   
                                            <NavLink to="/WhoWeArePage"  style={orangeText} className="mr-4 FooterMenuOptions" activeClassName='is-active'>
                                                Quienes somos
                                            </NavLink>
                                            <NavLink to="/OurProductsPage" style={orangeText}  className="mr-4 FooterMenuOptions" activeClassName='is-active'>
                                                Nuestros productos
                                            </NavLink>
                                            <NavLink to="/DownloadPage"  style={orangeText} className="mr-4 FooterMenuOptions" activeClassName='is-active'>
                                                Descarga
                                            </NavLink>
                                            <NavLink to="/ContactPage" style={orangeText}  className="mr-4 FooterMenuOptions" activeClassName='is-active'>
                                                Contacto
                                            </NavLink>
	                
					</Nav>
                    </Col>
                    <Col lg="auto" align="center" >
                        <BenefyIcon Width="50"/>
                    </Col>
                    <Col lg="auto" align="center">
                        <a href={facebook}><img alt="Facebook" src={FacebookIcon} className="m-2" width="30" /></a>
                        <a href={instagram} > <img alt="Instagram" src={InstagramIcon} className="m-2" width="30"/></a>
                        <a href={youtube} > <img alt="Youtube" src={YoutubeIcon} className="m-2" width="30"/></a>
                        <a href={linkedin}> <img alt="Linkedin" src={LinkedinIcon} className="m-2" width="30"/> </a>              
                        <h3 className="pt-2" style={{...colorGrayFooter,fontSize:"1.1rem"}}>Síguenos en:</h3>                    
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col  lg="auto" >
                        <p style={colorGrayFooter}>
                            Copyright @ 2021 Benefy todos los derechos reservados
                        </p>
                    </Col>
                </Row>
            </Container>
    )
})
