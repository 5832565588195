import React, { useEffect, useState } from 'react'
import { Button, Modal,Image, Row, Col, Container } from 'react-bootstrap'
import { useHistory, withRouter } from 'react-router';
import { ReactSVG } from 'react-svg';
import pagoporclick from '../../Style/Img/CheckOut/pago-por-clic.svg'
import { secundaryOrange } from '../../Style/Colors';
import logo_blanco from '../../Style/Img/logo_blanco.svg'
import LoadingSecundary from '../Icons/LoadingSecundary';
import { useFetch } from '../../Hooks/useFetch';
import { urlRequest } from '../../helpers/urlRequest';
import ErrorPage from '../../Pages/ErrorPage';
import { PostRequest } from '../../helpers/requestHelper';
const ButtonCardStyle={
  width: "14rem",
  border:"none",
  fontSize:"1.8rem",
  background:secundaryOrange
}
export const ModalPresentDetail =({showModal,country,idOrderPresent,Code}) => {
    const history = useHistory();
    const [showM, setShow] = useState(showModal);
    const [response, setResponse] = useState(null)
    const {urlMicroAppMovil}=urlRequest();
    const [isLoadingRequest, setIsLoadingRequest] = useState(true);
    let body={
        "country": country,
        "idOrderPresent": idOrderPresent
      }
    useEffect(() => {
        if(showM){
            PostRequest(body,`${urlMicroAppMovil}PresentCoupon/GetPresentCouponDetail`).then(result=>
                    {
                        setIsLoadingRequest(false);
                          setResponse(result);
                    }
                    );
                }
       
    }, [showM])
    
    const handleClose = () => {
      setShow(false);
      localStorage.setItem(`presentCart${country}`,"");
     history.replace("/");
    };
    useEffect(() => {
        setShow(showModal)
    }, [showModal])
  
   
   console.log(response)
    return (
        <Modal
        show={showM}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header  closeButton style={{background:secundaryOrange}}>
    <Container fluid className="p-0 m-0">
        <Row className="Justify-content-center text-center">
            <Col>
            <Image  src={logo_blanco}     style={{
                                                width:"145px"
                                            }}
                                            />

              <h1 className="text-center mt-3" style={{color:"white",fontSize:"1.5rem"}}>Detalle de tu compra</h1>
            </Col>
        </Row>
    </Container>
          
             
        </Modal.Header>
        <Modal.Body className="text-center">
            {
                isLoadingRequest?
                     <LoadingSecundary/>
                :

                 response?
                 response.isSuccess?
                                <>       
                                      <Image src={response.data.urlImage} style={{
                                                            width:"145px"
                                                        }}/>
                                            
                                    <h2 className="mt-3" style={{fontSize:"1rem"}}>{response.data.title}</h2>
                                    <p style={{fontSize:"1rem"}}>{response.data.subTitle}</p>
                                    <h3 className="mt-5">Código promocional</h3>
                                    <h1>
                                        {Code}
                                    </h1>
                                    <h3 className="mt-3">¿COMO CANJEARLO</h3>      
                                    <p>Comparte este código promocional con esa persona especial y dile que debe ingresarlo en el app para que le aparezca en la cuponera benefy. Recuérdale que también le llegará un correo electrónico con el código de regalo.</p>
                                </>
                            :
                            <ErrorPage textError={response.description}/>
                    :
                    <LoadingSecundary/>
                       
            }      
  
  </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    )
}
