import firebaseapp from 'firebase/app'
import 'firebase/auth'
import 'firebase/firebase-firestore'

//dev

// var config = {
//     apiKey: "AIzaSyAl-AZFKDQy1Ph547dhR6Pe-DWj6VTMYOA",
//     authDomain: "beneficiosdevbol.firebaseapp.com",
//     databaseURL: "https://beneficiosdevbol.firebaseio.com",
//     projectId: "beneficiosdevbol",
//     storageBucket: "beneficiosdevbol.appspot.com",
//     messagingSenderId: "797707140437",
//     appId: "1:797707140437:web:7ade8c1ed1bb5d2cd083dc",
//     measurementId: "G-LQB8VB3H9E"
//  };
 /*
//uat
var config = {
  apiKey:  "AIzaSyCYUeVCUlXSNmUASVTny0eKWtbiQSnKxBE",
  authDomain: "https://beneficios-uat.firebaseio.com",
  databaseURL: "https://beneficios-uat.firebaseio.com",
  projectId: "beneficios-uat",
  storageBucket: "beneficios-uat.appspot.com",
  messagingSenderId: "797707140437",
  appId: "1:426617820255:android:85fe6ca997e437ccb737ea",
  measurementId: "G-LQB8VB3H9E"
};
*/

var config = {
  apiKey:  "AIzaSyAB4tNM4Weysn9depbeshQA7xCe7Lvrskw",
  authDomain: "https://beneficios-1b534.firebaseio.com",
  databaseURL: "https://beneficios-1b534.firebaseio.com",
  projectId: "beneficios-1b534",
  storageBucket: "beneficios-1b534.appspot.com",
  messagingSenderId: "797707140437",
  appId: "1:426617820255:android:85fe6ca997e437ccb737ea",
  measurementId: "G-LQB8VB3H9E"
};

const fireApp=firebaseapp.initializeApp(config);
export default fireApp;