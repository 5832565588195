import React from 'react'
import { JackInTheBox } from 'react-awesome-reveal';
import { Col, Container, Row } from 'react-bootstrap'
import '../Style/Css/ErrorPage.css';
import ErrorFace from '../Style/Img/error.png'
const ErrorPage = ({title,textError,Isfluid=false}) => {
    return (
        <Container fluid={Isfluid}  className={!Isfluid ?"my-5":"m-0 p-0"}>
            <Row>
                <Col className={!Isfluid &&"pt-2 pb-5" } lg="12" align="center">
                
                    <JackInTheBox> 
                        <h1 className="ErrorPageTitle">{title || "¡Lo sentimos!"}</h1>
                        
                    <h2 className="ErroPageSubtitle">{textError || "Ocurrió un error al conectarse a nuestros servidores."}</h2>  
                        <img alt="Error Benefy" width="150" src={ErrorFace}/> 
                    </JackInTheBox>
                </Col>
            </Row>
     </Container>
    )
}
export default ErrorPage