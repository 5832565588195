import React from 'react'

export const PhoneIcon = ({ClassName,Width,Height}) => {
    return (
        <svg className={ClassName} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="19" height="20" viewBox="0 0 19 20">
  <image id="Teléfono" width={Width} height={Height} xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAUCAYAAABvVQZ0AAABhUlEQVQ4ja3UP0hWYRTH8Y9XrCFUDKHFKMiQ2lwCl3BJEISaGgJbgpaWFjc33VyaW6U1QgShqSYXwc0tqASHsqzhFSoKOXJuXC/3fd8b9Fvun3N+3+fec87zDHSeTGjQFTzGPG7iPH5gD1t4jg91Wx12Dqt4iqGmVVK/8AzL+Fm+LCoJo3iDpT4gGV/K/NE6bBCvMNMHUtdM+garsFhltpJ4nDU5bAGcTf8pbCz/vdRnXMZVXMJmC2D4xwK2iAs12Ne8/4ONFrDwLwZsoRaYylEoddQCFloI2HTtZRRzLu+HsdISNh2w8YbAo7x28K0lbLzoEribbY+a3cfHCvxTN1rRo/3r2en9BMfzjezwZMN2OgzYbhfYNbzECA7wMMGhdzn9Ve0WfeYoBnIHt3vklNos8vM7PZKu4y1e40GeKBdxq5IT/vUi52i1xcp38ALv8SXrVyr8R2U31xpq0FbhC//fjf4b97D9j6DID1/4z5xn37PgsUocfr0U8ciL/PCd6v8d2zgBh9hSmswom5oAAAAASUVORK5CYII="/>
</svg>

    )
}
