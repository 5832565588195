import { PostRequest } from "./requestHelper";
import { urlRequest } from "./urlRequest";

const {urlMicroAppMovil}=urlRequest();
export const round = (number, decimalPlaces) => {
    const factorOfTen = Math.pow(10, decimalPlaces)
    return Math.round(number * factorOfTen) / factorOfTen
  }
  export const removeByAttr = function(arr, attr, value){
    var i = arr.length;
    while(i--){
       if( arr[i] 
           && arr[i].hasOwnProperty(attr) 
           && (arguments.length > 2 && arr[i][attr] === value ) ){ 

           arr.splice(i,1);

       }
    }
    return arr;
}
export const clearCookies=()=>{
    localStorage.setItem('added',"");
    localStorage.setItem('cartBO',"");
    localStorage.setItem('cartSV',"");
    localStorage.setItem('cartGT',"");
    localStorage.setItem('xAccessmb',"");
}

export const GetUserData=async(country,idUserFirebase)=>{
    let body={
        "country":country,
        "idUserFirebase":idUserFirebase
        };
    let result=
   await PostRequest(body,`${urlMicroAppMovil}User/GetUser`).then(result=>
        {
            if(result.isSuccess){
              
              return {
                  user:result.data
              };
            }else{
               return null;
            }
        }
    ); 
    return result;
}
export const calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age_now--;
    }
    console.log(age_now);
    return age_now;
  }

  //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
export const  calcCrow=(lat1, lon1, lat2, lon2) =>
  {
    var R = 6371; // km
    var dLat = toRad(lat2-lat1);
    var dLon = toRad(lon2-lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c;
    return d;
  }

  // Converts numeric degrees to radians
  export const  toRad=(Value) =>
  {
      return Value * Math.PI / 180;
  }