import React, {useRef, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { BenefyIcon } from '../Components/Icons/BenefyIcon'
import { Redirect, useHistory } from 'react-router'
import { thirdGray } from '../Style/Colors'
import { Fade } from 'react-awesome-reveal'
import { getCurrentUserId, LoginUser } from '../helpers/firebaseHelper'
import { useToasts } from 'react-toast-notifications'
import LoadingSecundary from '../Components/Icons/LoadingSecundary'
import { setTokenUser } from '../Request/setTokenUser'
import { PostRequest } from '../helpers/requestHelper'
import { urlRequest } from '../helpers/urlRequest'
import firebase from 'firebase'
const ButtonStyle={
    width: "12rem",
    border:"none",
    fontWeight:"100",
    height:"3rem"
}
export const LoginPage = ({country}) => {
    const {urlMicroAppMovil}=urlRequest();
    const email=useRef();
    const password=useRef();
    const [validated, setValidated] = useState(false);
    const history=useHistory();
    const { addToast } = useToasts();
    const [Loading, setLoading] = useState(false);
    const [userData, setUserData] = useState(null);

    const RestorePassword=async()=>{

    }
    async function login(event) {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
          return;
        }
        setValidated(false);
        setLoading(true);
		const result=await LoginUser(email.current.value,password.current.value);
        if (result.isSuccess){
            addToast(result.message, {
                appearance: 'success',
                autoDismiss: true
              });
              const tokenR=await setTokenUser();
              if(tokenR.error){
                addToast("Ocurrió un error inesperado internamente", {
                    appearance: 'error',
                    autoDismiss: true
                  });
                  return;
              }else{
                let body={
                    "country":country,
                    "idUserFirebase":getCurrentUserId()
                    };
                
        let resultUserInfo= await PostRequest(body,`${urlMicroAppMovil}User/GetUser`).then(result=>
                    {
                        if(result.isSuccess){
                        console.log("sdfdffff");
                            localStorage.setItem("Nameus",result.data.names);
                           setUserData(result.data);
                          return {
                              user:result.data
                          };
                        }else{
                           return null;
                        }
                    }
                );
                if(userData){
                    localStorage.setItem("Nameus",resultUserInfo.names);
                  }
               
              }

              setLoading(false);
             
            history.replace('/');
        }else{
            addToast(result.message, {
                appearance: 'error',
                autoDismiss: true,

              });
        }
        setLoading(false);
	}
    if(getCurrentUserId())
    {
        if(userData){
            localStorage.setItem("Nameus",userData.names);
            return <Redirect to="/"/>
        }
        
    }
    if(Loading){
        return  <LoadingSecundary/>;
    }
    return (
        <>
        {
            Loading?
            <LoadingSecundary/>
            :
        <div style={{background:thirdGray}}>
             <Fade>
             <Container className="mt-5"   style={{background:"white"}}>
             <Form noValidate validated={validated} onSubmit={login}>
                    <Form.Row className="justify-content-center text-center">
                        <Form.Group as={Col} >
                        <BenefyIcon Width="80"/>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className="justify-content-center text-center">
                        <Form.Group as={Col}>
                        <p className="mt-4">
                            Estas a pocos pasos de empezar a recibir tus beneficios
                        </p>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row >
                    <Form.Group as={Col} className="justify-content-center mt-2">
                        
                            <h1>Login</h1>
                    </Form.Group>
                    </Form.Row>
                    <Form.Row className="justify-content-center text-center">
                   
                    <Form.Group className="group">
                        
                        <Form.Control type="email"  required className="inputBenefy" ref={email}/>
                            <span style={{color: "red"}}></span>
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label className="labelInputBenefy">Correo</label>
                   
                            <Form.Control.Feedback type="invalid">
                                                Por favor proporciona un correo valido
                                            </Form.Control.Feedback>
                    </Form.Group>
                    
                    </Form.Row>
                    <Form.Row className="justify-content-center text-center mt-3 mb-5">
                            <Form.Group className="group">
                                <Form.Control type="password"  required className="inputBenefy" ref={password}/>
                                    <span style={{color: "red"}}></span>
                                    <span className="highlight"></span>
                                    <span className="bar"></span>
                                    <label className="labelInputBenefy">Contraseña</label>
                        
                                    <Form.Control.Feedback type="invalid">
                                                        La contraseña es requerida
                                    </Form.Control.Feedback>
                            </Form.Group>
                    </Form.Row>
                    <Form.Row className="justify-content-center text-center mb-5 mt-2">
                        <Form.Group as={Col}>
                        <Button style={ButtonStyle} className="orangeButton rounded-pill py-0" type="submit">INGRESAR</Button>
                        
                         </Form.Group>
                    </Form.Row>
                <Row>
                    <Col>
                         <a onClick={RestorePassword}>
                             Restaurar contraseña
                         </a>
                    </Col>
                </Row>
        
            </Form>
            </Container>
            </Fade>
       </div>
    }
    </>
    )
}
