import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications';
import { date } from 'yup';
import { BenefyInput } from '../Components/FormElements/BenefyInput';
import LoadingSecundary from '../Components/Icons/LoadingSecundary';
import { getCurrentUserId } from '../helpers/firebaseHelper';
import { PostRequest } from '../helpers/requestHelper';
import { urlRequest } from '../helpers/urlRequest';
import { useFetch } from '../Hooks/useFetch';
import ErrorPage from './ErrorPage';
import { useFormik } from "formik";
import * as Yup from "yup";
const ButtonStyle={
    width: "12rem",
    border:"none",
    fontWeight:"100",
    height:"3rem"
}
export const UserProfilePage = (props) => {
    const {urlMicroAppMovil}=urlRequest();
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [identityDocument, setIdentityDocument] = useState('')
    const [isLoadingRequest, setIsLoadingRequest] = useState(false);
    const [idUser, setIdUser] = useState('')
    const { addToast } = useToasts();
    let body={
        "country":props.country,
        "idUserFirebase":getCurrentUserId()
        };
      
      const {response, error, isLoading }=useFetch(`${urlMicroAppMovil}User/GetUser`,
        {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
            },
              body: JSON.stringify(body) // body data type must match "Content-Type" header
          },props.country
        );
        useEffect(() => {
                    if(response){
                        if(response.isSuccess){
                        const{data}=response;
                        setName(data.names);
                        setLastName(data.lastNames);
                        setPhone(data.phone);
                        setBirthDate(data.birthDate?data.birthDate.slice(0,10):null);
                        setIdentityDocument(data.idDocument);
                        setIdUser(data.idUser);
                    }
                }
            
           
        }, [response])

        
        const formik = useFormik({
            
            initialValues: {
              name: name,
              lastName:lastName,
              identityDocument:identityDocument,
              phone:phone,
              birthDate:birthDate,
            },
            enableReinitialize: true,
            validationSchema: Yup.object({
                name: Yup.string()
                    .min(2, "2 carácteres como minimo")
                    .max(100, "100 caracteres como máximo")
                    .required("El nombre es requerido").nullable(),
                lastName: Yup.string()
                    .min(2, "2 carácteres como minimo")
                    .max(100, "100 caracteres como máximo")
                    .required("El apellido es requerido").nullable(),
                identityDocument:Yup.string().required("El documento de identidad es requerido").nullable(),
                phone:Yup.string().required('El teléfono es requerido').nullable(),
                birthDate:Yup.string().required('La fecha de nacimiento es requerida').nullable()
            }),
            onSubmit: values => {
                UpdateUserHandler(values);
            }
          });
         
            if(error){
                return <ErrorPage/>
            }
            if(response){
                
                if(response.code!==200){
                  return  <ErrorPage textError={response.description}/>
                }
            }
            if(isLoading){
                return  <LoadingSecundary/>;
            }
 
 
    
    const UpdateUserHandler=(values)=>
    {
       
        setIsLoadingRequest(true);
        const bodySendUserInfo={
            idUserFirebase:getCurrentUserId(),
            country: props.country,
            language: 0,
            names: values.name,
            lastNames:values.lastName,
            phone:values.phone ,
            birthDate: values.birthDate,
            idDocument:values.identityDocument,
            idUser:idUser
          };
        
          PostRequest(bodySendUserInfo,`${urlMicroAppMovil}User/UpdateUser`).then(result=>
            {
                if(result.isSuccess && !error){
                    addToast(`${result.Message}, Pérfil actualizado`, {
                        appearance: 'success',
                        autoDismiss: true
                      });
                      if(props.onUserSave){
                          let userInfo={
                            idUser:idUser,
                            identityCard:identityDocument,
                            phoneModel:"Web"
                          }
                          props.onUserSave(true,userInfo);
                      }
                }else{
                    addToast(result.Message || "Ocurrió un error al contactar al servidor", {
                        appearance: 'error',
                        autoDismiss: true,

                      });
                }
               
            }
            );
           
        
    }
    if(isLoadingRequest){
        return <LoadingSecundary/>;
    }
    return (
        <>
            <Container className="mt-5">
            <form noValidate onSubmit={formik.handleSubmit}>
            <Row className="text-center my-5">
                <Col>
                <h1>Confirma tu información</h1>
                </Col>
            </Row>
            <Row>
                <Col >
              
                <div className="group">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    required
                                                    value={formik.values.name}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Nombre</label>
                                                        {formik.errors.name && formik.touched.name && (
                                                                <p className="inputwarningP">{formik.errors.name}</p>
                                                            )}
                                        </div> 
                </Col>
                <Col>
                 <div className="group">
                <input
                                                    type="text"
                                                    name="lastName"
                                                    required
                                                    value={formik.values.lastName}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Apellido</label>
                                                        {formik.errors.lastName && formik.touched.lastName && (
                                                                <p className="inputwarningP">{formik.errors.lastName}</p>
                                                            )}
                                        </div> 
                </Col>
            </Row>
            <Row className="">
                <Col>
                   
                     <div className="group">
                <input
                                                    type="text"
                                                    name="identityDocument"
                                                    required
                                                    value={formik.values.identityDocument}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Documento</label>
                                                        {formik.errors.identityDocument && formik.touched.identityDocument && (
                                                                <p className="inputwarningP">{formik.errors.identityDocument}</p>
                                                            )}
                                        </div> 
                </Col>
                <Col>
              
                <div className="group">
                                                        <input
                                                            type="text"
                                                            name="phone"
                                                            required
                                                            value={formik.values.phone}
                                                            onChange={formik.handleChange}
                                                            className="inputBenefy"
                                                        />
                                                                <span style={{color: "red"}}></span>
                                                                <span className="highlight"></span>
                                                                <span className="bar"></span>
                                                                <label className="labelInputBenefy">Teléfono</label>

                                                                {formik.errors.phone && formik.touched.phone && (
                                                                        <p className="inputwarningP">{formik.errors.phone}</p>
                                                                    )}
                                                </div>   
               
                </Col>
            </Row>
            <Row>
                <Col>
                <div className="group">
                                                                <input
                                                                    type="date"
                                                                    name="birthDate"
                                                                    required
                                                                    value={formik.values.birthDate}
                                                                    onChange={formik.handleChange}
                                                                    className="inputBenefy"
                                                                />
                                                                        <span style={{color: "red"}}></span>
                                                                        <span className="highlight"></span>
                                                                        <span className="bar"></span>
                                                                        <label className="labelInputBenefy">Fecha de nacimiento</label>

                                                                        {formik.errors.birthDate && formik.touched.birthDate && (
                                                                                <p className="inputwarningP">{formik.errors.birthDate}</p>
                                                                            )}
                                                        </div> 
                </Col>
                <Col>
                </Col>
            </Row>
            <Row className="justify-content-center text-center my-3" >
                <Col>
                     <Button style={ButtonStyle} className="orangeButton rounded-pill py-0" type="submit" >Guardar</Button>
                    
                </Col>
            </Row>
            </form>
        </Container>
            
        </>
     )
}
