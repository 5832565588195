import { ToastProvider } from 'react-toast-notifications';
import { CountryProvider } from './Context/CountryContext';
import { UiProvider } from './Context/UIContext';
import {PrincipalPage} from './Pages/PrincipalPage'

function CommercialApp() {
  return (
  <ToastProvider
  autoDismissTimeout={8000}
  >
      <UiProvider>
        <CountryProvider>
            <PrincipalPage/>
        </CountryProvider>
      </UiProvider>
  </ToastProvider>
  );
}

export default CommercialApp;
