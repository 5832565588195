export const PostRequest=async(body,url)=>{
   const result={
        isSuccess:false,
        Message:"",
        data:null,
        code:0
    };
    const options={
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Bearer ${localStorage.getItem('xAccessmb')}`
        },
          body: JSON.stringify(body) // body data type must match "Content-Type" header
      };
      try {
        const res = await fetch(url, options);
        const json = await res.json();
        result.isSuccess=json.isSuccess;   
        result.Message =json.description ;
        result.code=json.code;
        if(result.isSuccess){result.data=json.data;}
        
      } catch (error) {
        result.isSuccess=false;
        result.Message="Error al conectarse al servidor";
        result.code=500;
      }
      return result;
}