import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications';
import { CardComponent } from '../Components/Commerce/CardComponent'
import { urlRequest } from'../helpers/urlRequest';
import { PostRequest } from '../helpers/requestHelper'
import LoadingSecundary from '../Components/Icons/LoadingSecundary';
import { Col, Container, Image, Row } from 'react-bootstrap';
import pagoporclick from '../Style/Img/CheckOut/pago-por-clic.svg'
import payConfirm from '../Style/Img/CheckOut/payConfirm.png'


export const PaymentGateway = ({country,idUser,idOrderPayment}) => {
    const [isLoadingRequest, setIsLoadingRequest] = useState(false);
    const [paySuccess, setPaySuccess] = useState(false)
    const { addToast } = useToasts();
    const {urlMicroPasarela}=urlRequest();
    const processPay=(payData)=>{
        let body={
            "country": country,
            "language": 0,
            "cardNumber": payData.cardNumber,
            "month": parseInt(payData.Cardmonth),
            "year": parseInt(payData.CardYear),
            "nameClient": payData.CardNameUser,
            "cvvCard": payData.CardCvv,
            "idOrderPayment": idOrderPayment,
            "idUser": idUser
          }
          setIsLoadingRequest(true);
       PostRequest(body,`${urlMicroPasarela}ProcessCardPayment/ProcessPayment`).then(result=>
            {
                setIsLoadingRequest(false);
               
                if(result.isSuccess){
                    addToast(`${result.Message}`, {
                        appearance: 'success',
                        autoDismiss: true
                      });
                      setPaySuccess(true);
                }else{
                    addToast(result.Message || "Ocurrió un error al contactar al servidor", {
                        appearance: 'error',
                        autoDismiss: true,

                      });
                }
               
            }
            );
    }
    return (
        <>
        {
            isLoadingRequest?
                <LoadingSecundary textTitle="Procesando el pago"/>
            :
            paySuccess?
            <Container>
                <Row className="justify-content-center text-center my-5">
                    <Col>
                    <h1>¡PAGO EXITOSO!</h1>
                         <Image className="mt-3" src={payConfirm} style={{
                                                            width:"16rem"
                                                        }}/>
                         <h3 className="mt-5">Felicidades, ya puedes disfrutar de tus productos desde el app móvil de Benefy.</h3>
                        
                    </Col>
                </Row>
            </Container>
            :
            <CardComponent actionMethod={processPay}/>
        }
        
        </>
    )
}
