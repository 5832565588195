export const urlRequest=()=>{
    return{
        //Local
          // urlCommerceMicro:"https://localhost:44319/api/",
          // urlMicroAppMovil:"http://172.30.126.112:8092/api/",
          // urlMicroPasarela:"https://localhost:44301/api/",
          // urlMicroNotificaciones:"http://172.30.126.112:8086/",
          // urlMicroSecurity:"http://172.30.126.112:8094/api/"
   //UAT
   /*   urlCommerceMicro:"https://commercial.uat.beneficioslatam.com/api/",
        urlMicroAppMovil:"https://backofficev2.uat.beneficioslatam.com/api/",
        urlMicroPasarela:"https://paymentgateway.uat.beneficioslatam.com/api/",
        urlMicroNotificaciones:"https://notifications.uat.beneficioslatam.com/",
        urlMicroSecurity:"https://security.uat.beneficioslatam.com/api/"
        */
 //PROD
 urlCommerceMicro:"https://commercial.beneficioslatam.com/api/",
  urlMicroAppMovil:"https://backofficev2.beneficioslatam.com/api/",
  urlMicroPasarela:"https://paymentgateway.beneficioslatam.com/api/",
  urlMicroNotificaciones:"https://notifications.beneficioslatam.com/",
  urlMicroSecurity:"https://security.beneficioslatam.com/api/"
}
}