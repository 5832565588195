import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Dropdown, Row } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import { countryFlagName } from '../../helpers/countryInfo';
import { getCurrentUserId } from '../../helpers/firebaseHelper';
import { urlRequest } from '../../helpers/urlRequest';
import { useFetch } from '../../Hooks/useFetch';
import ErrorPage from '../../Pages/ErrorPage';
import { BenefyInput } from '../FormElements/BenefyInput';
import LoadingSecundary from '../Icons/LoadingSecundary';
import { useFormik } from "formik";
import * as Yup from "yup";
const ButtonStyle={
    width: "12rem",
    border:"none",
    fontWeight:"100",
    height:"3rem"
}
const DocumentTypeList=[
    { code: 'BE', title: 'BE'},
    { code: 'CH', title: 'CH'},
    { code: 'CB', title: 'CB'},
    { code: 'LP', title: 'LP'},
    { code: 'OR', title: 'OR'},
    { code: 'PA', title: 'PA'},
    { code: 'PO', title: 'PO'},
    { code: 'SC', title: 'SC'},
    { code: 'TA', title: 'TA'},
] ;
const countries = [
    { code: 'BO', title: 'Bolivia',ext:"+591"},
    { code: 'SV', title: 'El Salvador',ext:"+503"},
    { code: 'GT', title: 'Guatemala',ext:"+502"},
    { code: 'CO', title: 'Colombia',ext:"+502"},
    { code: 'HN', title: 'Honduras',ext:"+502"},
  ];

export const InsuredInforCovid = (props) => {
    const {urlMicroAppMovil}=urlRequest();
    const [toggleContents, setToggleContents] = useState(<>{countryFlagName(props.country).ext}  </>);
    const [toggleContentsDocumentExt, setToggleDocumentExt] = useState(<>{DocumentTypeList[0].title}</>);
    const [toggleContentsBornCountry, setToggleBornCountry] = useState(<>{countries[0].title}</>);
    const [toggleContentsActualCountry, setToggleActualCountry] = useState(<>{countries[0].title}</>);
    const [documentExt, setDocumentExt] = useState(DocumentTypeList[0].title);
    const [bornCountry, setBornCountry] = useState(countries[0].title);
    const [actualCountry, setActualCountry] = useState(countries[0].title);
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [identityDocument, setIdentityDocument] = useState('');
    const [idUser, setIdUser] = useState('')
    const [phoneExt, setPhoneExt] = useState(countryFlagName(props.country).ext);
    let body={
        "country":props.country,
        "idUserFirebase":getCurrentUserId()
        };
      
      const {response, error, isLoading }=useFetch(`${urlMicroAppMovil}User/GetUser`,
        {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
            },
              body: JSON.stringify(body) // body data type must match "Content-Type" header
          },props.country
        );
        useEffect(() => {
                    if(response){
                        if(response.isSuccess){
                        const{data}=response;
                        setName(data.names);
                        setLastName(data.lastNames);
                        setPhone(data.phone);
                        setBirthDate(data.birthDate);
                        setIdentityDocument(data.idDocument);
                        setIdUser(data.idUser);
                    }
                }
        }, [response])
      
        const formik = useFormik({
            initialValues: {
                insuredFirstName: name,
                insuredMiddleName:"",
                insuredLastName:lastName,
                insuredOtherLastName:"",
                insuredIdentityDocument:phone,
                insuredBirthDate:birthDate,
                phoneNumber:phone,
                beneficiaryFullName:"",
                beneficiaryDocumentId:"",
                beneficiaryRelationship:"",
                homeAddress:""
            },
            validationSchema: Yup.object({
                insuredFirstName: Yup.string()
                    .min(2, "2 carácteres como minimo")
                    .max(15, "50 caracteres como máximo")
                    .required("El nombre es requerido"),
                insuredMiddleName: Yup.string()
                    .min(2, "2 carácteres como minimo")
                    .max(15, "50 caracteres como máximo"),
                insuredLastName: Yup.string()
                    .min(2, "2 carácteres como minimo")
                    .max(15, "50 caracteres como máximo")
                    .required("El apellido es requerido"),
                    insuredOtherLastName: Yup.string()
                    .min(2, "2 carácteres como minimo")
                    .max(15, "50 caracteres como máximo"),
                    insuredIdentityDocument:Yup.string().required("El documento es requerido")
                    .max(10, "10 caracteres como máximo"),
                    phoneNumber:Yup.string().required('El teléfono es requerido'),
                    insuredBirthDate:Yup.string().required('La fecha de nacimiento es requerida'),
                    beneficiaryFullName:Yup.string().required("Este campo es requerido"),
                    beneficiaryDocumentId:Yup.string().required("Este campo es requerido")
                    .max(10, "10 caracteres como máximo"),
                    beneficiaryRelationship:Yup.string().required("Este campo es requerido"),
                    homeAddress:Yup.string().required("Este campo es requerido")
            }),
            onSubmit: values => {
                values.phoneNumber=phoneExt+values.phoneNumber;
                const dataUser={
                    ...values,
                    documentExtension:documentExt,
                    userCountry:bornCountry,
                    userCountryResidence:actualCountry
                }
                console.log(dataUser);
                props.UserInfoAction(dataUser,true);
            }
          });
            if(error){
                return <ErrorPage/>
            }
            if(response){
                
                if(response.code!==200){
                  return  <ErrorPage textError={response.description}/>
                }
               
            }
            if(isLoading){
                return  <LoadingSecundary/>;
            }
 
    
  
    return (
       
            <Container className="mt-5">
         <form noValidate onSubmit={formik.handleSubmit}>
            <Row className="text-center my-5">
                <Col>
                <h2>Completa los siguientes datos para poder gozar de tu seguro.</h2>
                </Col>
            </Row>
            <Row>
                <Col >
                           <div className="group">
                                                <input
                                                    type="text"
                                                    name="insuredFirstName"
                                                    required
                                                    value={formik.values.insuredFirstName}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Primer Nombre</label>
                                                        {formik.errors.insuredFirstName && formik.touched.insuredFirstName && (
                                                                <p className="inputwarningP">{formik.errors.insuredFirstName}</p>
                                                            )}
                                        </div>  
                                        <div className="group">
                                                <input
                                                    type="text"
                                                    name="insuredMiddleName"
                                                    required
                                                    value={formik.values.insuredMiddleName}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Segundo Nombre</label>
                                                        {formik.errors.insuredMiddleName && formik.touched.insuredMiddleName && (
                                                                <p className="inputwarningP">{formik.errors.insuredMiddleName}</p>
                                                            )}
                                        </div> 
                  </Col>
                <Col>
                <div className="group">
                                                <input
                                                    type="text"
                                                    name="insuredLastName"
                                                    required
                                                    value={formik.values.insuredLastName}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Primer Apellido</label>
                                                        {formik.errors.insuredLastName && formik.touched.insuredLastName && (
                                                                <p className="inputwarningP">{formik.errors.insuredLastName}</p>
                                                            )}
                </div>
                <div className="group">
                                                <input
                                                    type="text"
                                                    name="insuredOtherLastName"
                                                    required
                                                    value={formik.values.insuredOtherLastName}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Segundo Apellido</label>
                                                        {formik.errors.insuredOtherLastName && formik.touched.insuredOtherLastName && (
                                                                <p className="inputwarningP">{formik.errors.insuredOtherLastName}</p>
                                                            )}
                </div> 
                </Col>
            </Row>
            <Row>
                <Col>
                <div className="group">
                                                <input
                                                    type="date"
                                                    name="insuredBirthDate"
                                                    required
                                                    value={formik.values.insuredBirthDate}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Fecha de nacimiento</label>
                                                        {formik.errors.insuredBirthDate && formik.touched.insuredBirthDate && (
                                                                <p className="inputwarningP">{formik.errors.insuredBirthDate}</p>
                                                            )}
                </div> 
                </Col>
                <Col>
                </Col>
            </Row>
            <Row className="text-center">
                <Col >
                <span className="d-inline">Extensión:</span>
                                <Dropdown
                                        className="mx-0 mr-md-1 mr-lg-1 d-inline"
                                        onSelect={eventKey => {
                                            setToggleDocumentExt(<> {DocumentTypeList.find(x=>x.code===eventKey).title}  </>); 
                                            setDocumentExt(eventKey);
                                            console.log(documentExt)
                                        }}
                                        >
                                        <Dropdown.Toggle variant="secondary" id="dropdown-flags" className="text-center" style={{ width: 150,background:"none",border:"none",color:"black" }}>
                                            {toggleContentsDocumentExt} 
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {DocumentTypeList.map(({ code, title }) => (
                                                <Dropdown.Item key={code} eventKey={code}>{title}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                </Dropdown>
                    </Col>
                <Col>
                <div className="group">
                                                <input
                                                    type="text"
                                                    name="insuredIdentityDocument"
                                                    required
                                                    value={formik.values.insuredIdentityDocument}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Documento de identidad</label>
                                                        {formik.errors.insuredIdentityDocument && formik.touched.insuredIdentityDocument && (
                                                                <p className="inputwarningP">{formik.errors.insuredIdentityDocument}</p>
                                                            )}
                </div> 
                </Col>
            </Row>
                                                            
           <Row>
               <Col className="text-right">
                     <span style={{fontSize:"2rem"}}>+591</span> 
                </Col>
                <Col>
                <div className="group">
                                                <input
                                                    type="text"
                                                    name="phoneNumber"
                                                    required
                                                    value={formik.values.phoneNumber}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Teléfono</label>
                                                        {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                                                                <p className="inputwarningP">{formik.errors.phoneNumber}</p>
                                                            )}
                </div>                                                   
                </Col>
           </Row>
          <Row>
              <Col>
              <span className="d-inline">País de origen:</span>
              <Dropdown
                                        className="mx-0 mr-md-1 mr-lg-1 d-inline"
                                        onSelect={eventKey => {
                                            setToggleBornCountry(<> {countries.find(x=>x.code===eventKey).title}  </>); 
                                            setBornCountry(eventKey);
                                        }}
                                        >
                                        <Dropdown.Toggle variant="secondary" id="dropdown-flags" className="text-center" style={{ width: 150,background:"none",border:"none",color:"black" }}>
                                            {toggleContentsBornCountry} 
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {countries.map(({ code, title }) => (
                                                <Dropdown.Item key={code} eventKey={code}>{title}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                </Dropdown>
                </Col>
                <Col>
                <span className="d-inline">País de actual:</span>
                <Dropdown
                                        className="mx-0 mr-md-1 mr-lg-1 d-inline"
                                        onSelect={eventKey => {
                                            setToggleActualCountry(<> {countries.find(x=>x.code===eventKey).title}  </>); 
                                            setActualCountry(eventKey);
                                        }}
                                        >
                                        <Dropdown.Toggle variant="secondary" id="dropdown-flags" className="text-center" style={{ width: 150,background:"none",border:"none",color:"black" }}>
                                            {toggleContentsActualCountry} 
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {countries.map(({ code, title }) => (
                                                <Dropdown.Item key={code} eventKey={code}>{title}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                </Dropdown>
                </Col>
          </Row>
          <Row className="mt-3">
              <Col>
              <div className="group">
                                                <input
                                                    type="text"
                                                    name="homeAddress"
                                                    required
                                                    value={formik.values.homeAddress}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Dirección de casa</label>
                                                        {formik.errors.homeAddress && formik.touched.homeAddress && (
                                                                <p className="inputwarningP">{formik.errors.homeAddress}</p>
                                                            )}
                </div>
              </Col>
          </Row>
            <Row className="mt-3">
                <Col>
                <h2 className="text-center">Información del beneficiario</h2>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>
                <div className="group">
                                                <input
                                                    type="text"
                                                    name="beneficiaryFullName"
                                                    required
                                                    value={formik.values.beneficiaryFullName}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Nombre completo</label>
                                                        {formik.errors.beneficiaryFullName && formik.touched.beneficiaryFullName && (
                                                                <p className="inputwarningP">{formik.errors.beneficiaryFullName}</p>
                                                            )}
                </div>
              </Col>
                <Col>
                <div className="group">
                                                <input
                                                    type="text"
                                                    name="beneficiaryDocumentId"
                                                    required
                                                    value={formik.values.beneficiaryDocumentId}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Documento</label>
                                                        {formik.errors.beneficiaryDocumentId && formik.touched.beneficiaryDocumentId && (
                                                                <p className="inputwarningP">{formik.errors.beneficiaryDocumentId}</p>
                                                            )}
                </div>

                 </Col>
            </Row>
            <Row>
                <Col>
                <div className="group">
                                                <input
                                                    type="text"
                                                    name="beneficiaryRelationship"
                                                    required
                                                    value={formik.values.beneficiaryRelationship}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Parentesco</label>
                                                        {formik.errors.beneficiaryRelationship && formik.touched.beneficiaryRelationship && (
                                                                <p className="inputwarningP">{formik.errors.beneficiaryRelationship}</p>
                                                            )}
                </div>
                 </Col>
                  <Col>
             
                </Col>
            </Row>
            <Row className="justify-content-center text-center my-3" >
                <Col>
                     <Button style={ButtonStyle} className="orangeButton rounded-pill py-0" type="submit" >Continuar</Button>                   
                </Col>
            </Row>
            </form>
        </Container>
        
     )
}
