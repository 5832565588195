import  { useContext, useEffect } from 'react'
import { UIContext } from '../Context/UIContext';

export const useLoading = (Loading) => {
    const {showLoading,hideLoading}=useContext(UIContext);
     
     useEffect(() => {
       if(Loading){
        document.body.style.overflow = "hidden";
        showLoading();
       }else{
        document.body.style.overflow = "visible";
        hideLoading();
       }
    }, [Loading,showLoading,hideLoading])
}
