import ElSalvador from '../Style/Img/ElSalvadorFlag.png'
import Guatemala from '../Style/Img/FlagGuatemala.svg'
import FlagBolivia from '../Style/Img/FlagBolivia.svg'
export const countryCurrency=(country)=>{
    switch(country){
        case 'SV':
            return '$';
        case 'BO':
            return 'Bs';
        case 'GT':
            return 'Q';
            default:
            return '$';
    }
}

export const countryFlagName=(country)=>{
    switch(country){
        case 'SV':
            return {name:'El Salvador',icon:ElSalvador,code:'SV',ext:" +503"};
        case 'BO':
            return {name:'Bolivia',icon:FlagBolivia,code:'BO',ext:" +591"};
        case 'GT':
            return {name:'Guatemala',icon:Guatemala,code:'GT',ext:" +502"};
        default:
            return {name:'El Salvador',icon:ElSalvador,code:'SV',ext:" +503"};
    }
}

export const countryDefaultCoords=(country)=>{
    switch(country){
        case 'SV':
            return {
                lat: 13.694024,  lng: -89.250191
              };
        case 'BO':
            return {
                lat: -17.741243,  lng:-63.163620
              };
        case 'GT':
            return {
                lat: 15.108638,  lng: -90.535805
              };
            default:
            return {
                lat: 13.694024,  lng: -89.250191
              };
    }
}