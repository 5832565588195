import React, { useContext, useState } from 'react'
import { Fade, Zoom } from 'react-awesome-reveal'
import { Button, Col, Container, Row, Table } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'
import EmptySection from '../Components/Sections/EmptySection'
import { countryCurrency } from '../helpers/countryInfo'
import { removeByAttr, round } from '../helpers/functionsHelpers'
import { PostRequest } from '../helpers/requestHelper'
import { grayPrimary, graySecundary, secundaryOrange } from '../Style/Colors'
import '../Style/Css/CartCheckout.css'
import { urlRequest } from '../helpers/urlRequest';
import { UserProfilePage } from './UserProfilePage'
import creditcard from '../Style/Img/CheckOut/credit-card.svg'
import { ReactSVG } from 'react-svg'
import FullScreenLoader from '../Components/Loading/FullScreenLoader'
import { InsuredContract } from './InsuredContract'
import { withRouter } from 'react-router'
import { PresentInfo } from '../Components/Sections/PresentInfo'
import { PaymentGateway } from './PaymentGateway'
import movingDelivery from '../Style/Img/CheckOut/movingDelivery.png'
const ButtonCardStyle={
    width: "100%%",
    border:"none",
    fontSize:"1rem",
    background:secundaryOrange
}
function Iframe(props) {
    return (<div style={{height:"100vh"}} dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
  }
export const CartCheckoutPage = withRouter(({match,country}) => {
    let itemPriceTotal=0;
    let productsList=localStorage.getItem(`cart${country}`) || "[]";
    productsList=JSON.parse(productsList);
    let presentProductList=localStorage.getItem(`presentCart${country}`) || "[]";
    presentProductList=JSON.parse(presentProductList);
    let deliveryItems=localStorage.getItem(`delivery${country}`) || "[]";
    deliveryItems=JSON.parse(deliveryItems);
    let deliveryInfo=localStorage.getItem(`deliveryDetail${country}`) || "[]";
    deliveryInfo=JSON.parse(deliveryInfo);
    const type=parseInt(match.params.type);
    const [payPending, setPayPending] = useState(false);
    const [cart, setCart] = useState(type==1?presentProductList: type==2?deliveryItems:productsList);
    const [userData, setUserData] = useState({});
    const [showUserProfile, setShowUserProfile] = useState(false);
    const { addToast } = useToasts();
    const {urlMicroPasarela}=urlRequest();
    const [isLoadingRequest, setIsLoadingRequest] = useState(false);
    const [urlPay, setUrlPay] = useState("");
    const [isAssurance, setisAssurance] = useState(false);
    const [userSaved, setUserSaved] = useState(false);
    const [elementType, setElementType] = useState(0);
    const [presentInfo, setPresentInfo] = useState(null);
    const [showCardGateway, setShowCardGateway] = useState(false);
    const [idOrderPayment, setIdOrderPayment] = useState(null);
    const [deliveryData, setDeliveryData] = useState(deliveryInfo);
    let totalPrice=0;

    const removeItem=(id)=>{
    if(type===1){
        let presentProductsList=localStorage.getItem(`presentCart${country}`) || "[]";
        presentProductsList=JSON.parse(presentProductsList);
        presentProductsList=removeByAttr(presentProductsList,'id',id);
        localStorage.setItem(`presentCart${country}`, JSON.stringify(presentProductsList));
        setCart(presentProductsList);
    }else if(type===2){
        let productsList=localStorage.getItem(`delivery${country}`) || "[]";
        productsList=JSON.parse(productsList);
        productsList=removeByAttr(productsList,'id',id);
        localStorage.setItem(`delivery${country}`, JSON.stringify(productsList));
        setCart(productsList);
    }
    else{
        let productsList=localStorage.getItem(`cart${country}`) || "[]";
        productsList=JSON.parse(productsList);
        productsList=removeByAttr(productsList,'id',id);
        localStorage.setItem(`cart${country}`, JSON.stringify(productsList));
        setCart(productsList);
    }
       
    }
    const updateItemQuantity=(id,quantity)=>{
        if(type===1)
        {
            let presentProductsList=localStorage.getItem(`presentCart${country}`) || "[]";
            presentProductsList=JSON.parse(presentProductsList);
            var foundIndex = presentProductsList.findIndex(x => x.id == id);
            presentProductsList[foundIndex].quantity = quantity;
            localStorage.setItem(`presentCart${country}`, JSON.stringify(presentProductsList));
            setCart(presentProductsList);
        }else if(type===2){
            let productsList=localStorage.getItem(`delivery${country}`) || "[]";
            productsList=JSON.parse(productsList);
            var foundIndex = productsList.findIndex(x => x.id == id);
            productsList[foundIndex].quantity = quantity;
            localStorage.setItem(`delivery${country}`, JSON.stringify(productsList));
            setCart(productsList);
        }
        else{
            let productsList=localStorage.getItem(`cart${country}`) || "[]";
            productsList=JSON.parse(productsList);
            var foundIndex = productsList.findIndex(x => x.id == id);
            productsList[foundIndex].quantity = quantity;
            localStorage.setItem(`cart${country}`, JSON.stringify(productsList));
            setCart(productsList);
        }
    }
    const onClickCheckout=(presentData)=>{
        setPresentInfo(presentData);
        cart.map(item=>{
            
            if(item.ElementType===5 || item.ElementType===6 || item.ElementType===11 )
            {
                setisAssurance(true);
                setElementType(item.ElementType);
            }
        })
        setShowUserProfile(true)
    }
    const onUserSave=(userUpdated,userInfo)=>{
        
        setUserSaved(true);
        setUserData(userInfo);
       
        if(isAssurance){
            return;
        }else{
            processPay(userUpdated,userInfo);
        }
    }
    const processPay=(userUpdated,userInfo,contractData)=>{
        
        setIsLoadingRequest(true);
        if(userUpdated){
            let productsList=[]
            cart.map(pr=>{
                productsList.push(
                    {
                        idProduct:pr.id,
                        quantity:parseInt(pr.quantity),
                        amount:pr.ElementType===2?parseFloat(pr.quantity): parseFloat(pr.price),
                        elementType:pr.ElementType
                    }
                )
            })
        
        let bodyCreatePayOrder={
            country:country,
            products:productsList,
            userInfo,
            questionnaire:contractData?contractData:null,
            presentDetail:presentInfo
           }
        if(type===2){
           let itemsTotalPrice=cart.reduce(function(accumulator, currentValue) {
                return accumulator + currentValue.price;
            }, 0) ;
            let deliveryDetail={
                "direction": deliveryData.deliveryData.direction,
                "latitude": deliveryData.userCoords.lat,
                "longitude": deliveryData.userCoords.lng,
                "idProvince": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                "contactName": deliveryData.deliveryData.personReceives,
                "contactPhone": deliveryData.deliveryData.phone,
                "contactEmail": deliveryData.deliveryData.email,
                "idDispatchPoint": deliveryData.idPoint,
                "deliveryDate": deliveryData.deliveryData.deliveryDate,
                "startTime":  deliveryData.deliveryData.startTime,
                "endTime":  deliveryData.deliveryData.endTime,
                "deliveryPaid": true,
                "deliveryAmount": deliveryData.distancePrice,
                "itemsPrice": itemsTotalPrice
            }
            bodyCreatePayOrder={
                ...bodyCreatePayOrder,
                deliveryDetail:deliveryDetail
                }
                
        console.log(bodyCreatePayOrder);
        }
           PostRequest(bodyCreatePayOrder,`${urlMicroPasarela}Purchase/CreatePaymentOrder`).then(result=>
            {
                setIsLoadingRequest(false);
                
                if(result.isSuccess){
                    addToast(`${result.Message}, Pérfil actualizado`, {
                        appearance: 'success',
                        autoDismiss: true
                      });

                      if(result.data.payType===1){
                          setPayPending(true);
                          setUrlPay(result.data.urlPayment);
                      }else{
                           setIdOrderPayment(result.data.idOrder);
                           setShowCardGateway(true);
                      }

                }else{
                    addToast(result.Message || "Ocurrió un error al contactar al servidor", {
                        appearance: 'error',
                        autoDismiss: true,

                      });
                }
               
            }
            );
        }
    }
    if(payPending){
        console.log(urlPay)
        const iframe = `<iframe style="width: 100%; height:100%;"  title="fx." src="${urlPay}" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>`;        
        return <Iframe iframe={iframe} />;
    }
    if(isLoadingRequest){
       return<Container style={{height:"100vh"}}><FullScreenLoader/></Container> 
       
    }
    const contractInfoSave=(contractData)=>{
        console.log(contractData);
        processPay(true,userData,contractData);
    }
    if(showCardGateway){ 
        return <PaymentGateway idUser={userData.idUser} idOrderPayment={idOrderPayment} country={country} />
    }
    if(type===2)
    {
        totalPrice=parseFloat(totalPrice)+ parseFloat(deliveryData.distancePrice);  
    }
      
    return(
    <Container style={{height:"100%"}}>
        <Row>
            <Col>
            {
                    showUserProfile?
                    isAssurance && userSaved?
                   
                    <InsuredContract elementType={elementType} country={country} onContractSave={contractInfoSave}/>
                    :
                    <UserProfilePage country={country} onUserSave={onUserSave}/>
                :
                <Container fluid style={{width:"100%"}}>
                <Fade>
                <Row  className="justify-content-center text-center mt-5 py-1 py-lg-3 py-md-3 py-sm-3 px-2">
                    <Col lg="12" md="12">
                    <Table >
                        <thead>
                            <tr  style={{background:secundaryOrange}}>
                                <th  className="CartCheckoutHeaderText">
                                    IMAGEN
                                </th >
                                <th  className="CartCheckoutHeaderText">
                                PRODUCTO
                                </th>
                                <th  className="CartCheckoutHeaderText">
                                PRECIO
                                </th>
                                <th  className="CartCheckoutHeaderText">
                                CANTIDAD
                                </th>
                                <th  className="CartCheckoutHeaderText">
                                TOTAL
                                </th>
                                <th>

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            { 
                            cart.length>0?
                                cart.map((item,i)=>{
                                    let totalItem=round(item.price*item.quantity,2);
                                    if(item.ElementType===2){
                                        totalItem=item.quantity;
                                    }
                                    totalPrice=parseFloat(totalPrice)+ parseFloat(totalItem);   
                                    itemPriceTotal=itemPriceTotal+totalItem;            
                                return <>
                                    <tr key={i}>
                                        <td>
                                            <img  src={item.image} className="mt-4 mb-2 CartCheckoutImageCoupon"/>
                                        </td>
                                        <td>
                                            <h1 className=" text center mt-5 CartCheckoutTitleElement">{item.title}</h1>
                                            <p className=" text-center CartCheckoutSubTitleElement"> {item.subTitle}</p>
                                        </td>
                                        <td>
                                        <h1 className="mt-5 CartCheckoutPrice">{`${countryCurrency(country)} ${item.ElementType===2?totalItem:item.price}`}</h1>
                                        </td>
                                        <td>
                                        <p className="mr-1 mt-5 d-inline" style={{ fontWeight: "100"}}>Cantidad</p> <input className=" mt-5 mb-auto text-center" style={{width:"3.3rem"}} type="number" onBlur={e=>updateItemQuantity(item.id,e.target.value)} defaultValue={item.quantity} min={item.ElementType===2?item.price:"1"} step={item.ElementType===2?"5":"1"}/>                          
                                        </td>
                                        <td>
                                            <h1 className="mt-5 CartCheckoutPrice">{`${countryCurrency(country)} ${totalItem}`}</h1>
                                        </td>
                                        <td>
                                            <button onClick={()=>removeItem(item.id)} className="mt-5" style={{background:"none", border:"none"}} ><span style={{color:grayPrimary, fontSize:"1.5rem"}}>X</span></button>
                                        </td>
                                    </tr>
                                
                                </>})
                                :
                                <tr>
                                    <td colSpan="6">
                                    <EmptySection textMessage="El carrito esta vacío."/>
                                    </td>
                                </tr>
                                }
                                {
                                    type===2 &&
                                   <>
                                    <tr >
                                        <td>
                                            <img  src={movingDelivery} className="mt-4 mb-2 CartCheckoutImageCoupon"/>
                                        </td>
                                        <td>
                                            <h1 className=" text center mt-5 CartCheckoutTitleElement">Delivery</h1>
                                            <p className=" text-center CartCheckoutSubTitleElement"> Costo del delivery</p>
                                        </td>
                                        <td>
                                        <h1 className="mt-5 CartCheckoutPrice">{`${countryCurrency(country)} ${deliveryData.distancePrice}`}</h1>
                                        </td>
                                        <td>
                                        <p className="mr-1 mt-5 d-inline" style={{ fontWeight: "100"}}>Cantidad</p> <input className=" mt-5 mb-auto text-center" value="1" style={{width:"3.3rem"}} type="number" readOnly/>                          
                                        </td>
                                        <td>
                                            <h1 className="mt-5 CartCheckoutPrice">{`${countryCurrency(country)} ${deliveryData.distancePrice}`}</h1>
                                        </td>
                                        <td>
                                            <button  className="mt-5" style={{background:"none", border:"none"}} ><span style={{color:grayPrimary, fontSize:"1.5rem"}}></span></button>
                                        </td>
                                    </tr>
                                
                                </>
                                    }
                        </tbody>
                    
                    </Table>
                    <hr />
                    </Col>
                </Row>
                {
                    type===1 && cart[0]?
                    <PresentInfo country={country} title={cart[0].title} subtitle={cart[0].subTitle} price={totalPrice} actionMethod={onClickCheckout}/>
                    :
                    <Row className="mb-5 justify-content-center text-center">
                    <Col lg="6">
                    </Col>
                    <Col lg="6">
                        <Table>
                            <thead>
                                <tr  style={{background:secundaryOrange}}>
                                    <td className="CartCheckoutHeaderText">
                                        TOTAL CARRITO
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>SUBTOTAL</p>
                                    </td>
                                    <td align="right">
                                    <p>
                                            {`${countryCurrency(country)} ${totalPrice}`}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="CartCheckoutPrice">
                                            TOTAL
                                        </h2>
                                    </td>
                                    <td align="right">
                                        <h2 className="CartCheckoutPrice">
                                            {`${countryCurrency(country)} ${totalPrice}`}
                                        </h2>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                    </td>
                                    <td align="right">
                                   {  cart.length>0 && <Button style={ButtonCardStyle} className="py-2 ml-4" onClick={()=>onClickCheckout()}>
                                            REALIZAR COMPRA
                                    </Button>}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    
                    </Col>
                    
                </Row>
               
                }
                </Fade>
            </Container>   

            }
            </Col>
        </Row>
           
    </Container>
      )
})
