import React, { useEffect, useState } from 'react'
import { Button, Image, Modal } from 'react-bootstrap'
import { useHistory, withRouter } from 'react-router';
import { ReactSVG } from 'react-svg';
import pagoporclick from '../../Style/Img/CheckOut/pago-por-clic.svg'
import { secundaryOrange } from '../../Style/Colors';
const ButtonCardStyle={
  width: "14rem",
  border:"none",
  fontSize:"1.8rem",
  background:secundaryOrange
}
export const PaymentSuccess =withRouter(({showModal,country,notificationBody,methodAction}) => {
    const history=useHistory();
    const [show, setShow] = useState(showModal);
    const handleClose = () => {
      setShow(false);
     localStorage.setItem(`cart${country}`,"");
     history.replace("/");
    };
    useEffect(() => {
        setShow(showModal)
    }, [showModal])
  
    const cardBody=()=>{
        if(notificationBody.NotificationType==="5"){
          return <>
                     <h3 style={{fontSize:"1.5rem"}} className="mt-4">{notificationBody.BeneficiaryName} ha recibido un correo electrónico con un código de regalo, adicionalmente puedes enviárselo tú mismo haciendo tab en el botón 'Ver regalo'.</h3>
                     <Button style={ButtonCardStyle} className="rounded-pill py-0 mt-4 " onClick={()=>{methodAction(notificationBody.IdProduct,notificationBody.Code)}}>
                                            Ver regalo
                                    </Button>  
                   </>
        }
        return    <h3 className="mt-4">Felicidades, ya puedes disfrutar de tus productos desde el app móvil de Benefy.</h3>
       
    }
   
    return (
        <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title><h1>Pago exitoso</h1></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p className="text-center">Revisa tu email para ver el recibo.</p>
    
                                    <Image src={pagoporclick} style={{
                                                            width:"145px"
                                                        }}/>
                            {
                              notificationBody?
                                cardBody()
                              :
                                <h3 className="mt-4">Felicidades, ya puedes disfrutar de tus productos desde el app móvil de Benefy.</h3>
                        
                            }
                            
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    )
})
