import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container,  Dropdown,  Form,  Row } from 'react-bootstrap'
import { BenefyIcon } from '../Components/Icons/BenefyIcon'
import { thirdGray } from '../Style/Colors'
import { Fade } from 'react-awesome-reveal'
import { countryFlagName } from '../helpers/countryInfo'
import { useFetch } from '../Hooks/useFetch'
import { urlRequest } from '../helpers/urlRequest'
import ErrorPage from './ErrorPage'
import LoadingSecundary from '../Components/Icons/LoadingSecundary'
import { getCurrentUserId, RegisterUser } from '../helpers/firebaseHelper'
import { useToasts } from 'react-toast-notifications'
import { PostRequest } from '../helpers/requestHelper'
import moment from 'moment'
import { useFormik } from "formik";
import * as Yup from "yup";
import '../Style/Css/BenefyInputs.css';
const ButtonStyle={
    width: "12rem",
    border:"none",
    fontWeight:"100",
    height:"3rem"
}
const countries = [
    { code: 'BO', title: 'Bolivia'},
    { code: 'SV', title: 'El Salvador'},
    { code: 'GT', title: 'Guatemala'}
  ];
const genreList=[
    { code: 'Masculino', title: 'Masculino'},
    { code: 'Femenino', title: 'Femenino'}
]
const statusList=[
    { code: 'Soltero', title: 'Soltero'},
    { code: 'Comprometido', title: 'Comprometido'},
    { code: 'Casado', title: 'Casado'},
    { code: 'Separado', title: 'Separado'},
    { code: 'Divorciado', title: 'Divorciado'},
    { code: 'Viudo', title: 'Viudo'}
] 
let Initialcountry="BO"


export const RegisterPage = ({country}) => {
    const [validated, setValidated] = useState(false);
    const { addToast } = useToasts();
    const {urlMicroAppMovil}=urlRequest();
    const [toggleContents, setToggleContents] = useState(<><img alt="Pais" style={{width: "30px"}} src={countryFlagName(Initialcountry).icon}/> {countryFlagName(Initialcountry).name}  </>);
    const [toggleContentsGenre, setToggleContentsGenre] = useState(<>{genreList[0].title}</>);
    const [toggleContentsStatus, setToggleContentsStatus] = useState(<>{statusList[0].title}</>);
    const [toggleContentsStates, setToggleContentsStates] = useState("");
    const [toggleContentsCities, setToggleContentsCities] = useState("");
    const [finalCountry, setfinalCountry] = useState(countryFlagName(Initialcountry).code);
    const [phoneExt, setPhoneExt] = useState(countryFlagName(Initialcountry).ext)
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [genre, setGenre] = useState(genreList[0].title);
    const [maritalStatus, setMaritalStatus] = useState(statusList[0].title);
    const [finalState, setFinalState] = useState("");
    const [finalcity, setFinalcity] = useState("");
    const [Loading, setLoading] = useState(false);
    const [successUser, setSuccessUser] = useState(false)
    let body={
        "country":country
        };
    const {response, error, isLoading }=useFetch(`${urlMicroAppMovil}Country/GetStates`,
        {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
            },
              body: JSON.stringify(body) // body data type must match "Content-Type" header
          },country
        );
    useEffect(() => {
        if(response)
        {
            if(response.isSuccess){
                setStates(response.data);
                
               setCities(response.data[0].cities);
               setFinalState(response.data[0].name);
               setFinalcity(response.data[0].cities[0].name);
               setToggleContentsStates(<>{response.data[0].name}</>);
               setToggleContentsCities(<>{response.data[0].cities[0].name}</>);
              
            }
        }
    }, [response])
  const formik = useFormik({
    initialValues: {
      name: "",
      lastName:"",
      phone:"",
      birthDate:"",
      email: "",
      password: "",
      confirm_password: ""
    },
    validationSchema: Yup.object({
        name: Yup.string()
            .min(2, "2 carácteres como minimo")
            .max(15, "50 caracteres como máximo")
            .required("El nombre es requerido"),
        lastName: Yup.string()
            .min(2, "2 carácteres como minimo")
            .max(15, "50 caracteres como máximo")
            .required("El nombre es requerido"),
        phone:Yup.string().required('El teléfono es requerido'),
        birthDate:Yup.string().required('La fecha de nacimiento es requerida'),
        email: Yup.string()
            .email("Formato del correo inválido")
            .required("El correo es requerido"),
        password: Yup.string()
            .min(8, "8 caracteres como minimos")
            .required("La contraseña es requerida"),
        confirm_password: Yup.string()
            .oneOf([Yup.ref("password")], "Las contraseñas no coinciden")
            .required("Es necesario confirmar la contraseña")
    }),
    onSubmit: values => {
        onRegister(values);
    }
  });
 
        if(error){
            return <ErrorPage/>
        }
        if(response){
            
            if(response.code!==200){
              return  <ErrorPage textError={response.description}/>
            }
        }
        if(isLoading){
            return  <LoadingSecundary/>;
        }
    
    
    const onRegister=async(data)=>{
      
      setLoading(true);
      
      const resultCreate=await RegisterUser(data.email,data.password);
      
      if(resultCreate.isSuccess){
        const actualDate= moment().format("YYYY-MM-DD");
        const bodyUserRegister={
            "country": country,
            "language": 0,
            "idUserFirebase": getCurrentUserId(),
            "names": data.name,
            "lastNames": data.lastName,
            "countryCode": finalCountry,
            "phone": data.phone,
            "birthDate":data.birthDate,
            "gender": genre,
            "maritalStatus": maritalStatus,
            "state": finalState,
            "city": finalcity,
            "photoUrl": "",
            "email": data.email,
            "tycDate": actualDate,
            "flagTyc": true,
            "actualCountry": country,
            "store": 4
        }
        PostRequest(bodyUserRegister,`${urlMicroAppMovil}User/SaveUser`).then(result=>
            {
              setLoading(false);
              console.log(result);
                if(result.isSuccess){
                    addToast(`${result.Message}`, {
                        appearance: 'success',
                        autoDismiss: true
                       
                      });
                     setSuccessUser(true);
                }else{
                    
                    addToast(result.Message, {
                        appearance: 'error',
                        autoDismiss: true,

                      });
                }
               
            }
            );
        
      }else{
        addToast(resultCreate.message, {
            appearance: 'error',
            autoDismiss: true,

          });
      }
      setLoading(false);
    }
    
    const countryEvent=()=>{

    }
    const onStateChange=(idState)=>{
        let stateTMP=states.find(x=>x.idState===idState);
      setCities(stateTMP.cities);
      setToggleContentsCities(<>{stateTMP.cities[0].name}</>);
    } 
    
    return (
        <>
        {
            Loading?
            <LoadingSecundary/>
            :
            successUser
            ?
                <div style={{background:thirdGray}}>
                <Fade>
                <Container className="mt-5"   style={{background:"white"}}>

                <Row className="justify-content-center text-center mb-5">
                    <Col>
                    <BenefyIcon Width="80"/>
                    </Col>
                </Row>
                <Row className="justify-content-center text-center mb-5">
                    <Col>
                        <h2>
                            Cuenta creada con exito
                        </h2>
                    </Col>
                </Row>
                <Row className="justify-content-center text-center my-5">
                    <Col>
                           <a style={{...ButtonStyle,color:"white"}} className="orangeButton rounded-pill py-3 px-4" href="/">Aceptar</a>
                    </Col>
                </Row>
                </Container>
                </Fade>
                </div>
            :
            <div style={{background:thirdGray}}>
            <Fade>
                    <Container className="mt-5"   style={{background:"white"}}>
                    <form noValidate onSubmit={formik.handleSubmit}>
                    
                                <Row className="justify-content-center text-center mb-5">

                                    <Col>
                                    <BenefyIcon Width="80"/>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center text-center mb-5">
                                <Col>
                                        <h2>
                                            Crear Cuenta
                                        </h2>
                                </Col>
                              </Row>
                                <Row  className="justify-content-center text-md-center text-lg-center text-left">
                                    <Col lg="4" md="4" sm="4" xs="12" >
                                    <div className="group">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    required
                                                    value={formik.values.name}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Nombre</label>
                                                        {formik.errors.name && formik.touched.name && (
                                                                <p className="inputwarningP">{formik.errors.name}</p>
                                                            )}
                                        </div> 
                                    </Col>
                                    <Col  lg="4" md="4" sm="4" xs="12" >
                                    <div className="group">
                                                <input
                                                    type="text"
                                                    name="lastName"
                                                    required
                                                    value={formik.values.lastName}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Apellido</label>

                                                        {formik.errors.lastName && formik.touched.lastName && (
                                                                <p className="inputwarningP">{formik.errors.lastName}</p>
                                                            )}
                                        </div> 
                                        </Col>
                                </Row >
                               <Row  className="justify-content-center text-md-center text-lg-center text-left mt-3">
                                    <Col lg="3" md="3" sm="3" xs="12">
                                        <Dropdown
                                            className="mr-0 mr-md-1 mr-lg-1 "
                                                    onSelect={eventKey => {
                                                        setToggleContents(<><img alt={countryFlagName(eventKey).name} style={{width: "30px"}} src={countryFlagName(eventKey).icon}/> {countryFlagName(eventKey).name}  </>);                                                         
                                                        countryEvent(eventKey);       
                                                        setfinalCountry(countryFlagName(eventKey).code);
                                                        setPhoneExt(countryFlagName(eventKey).ext)                    
                                                    }}
                                                    >
                                                    <Dropdown.Toggle variant="secondary" id="dropdown-flags" className="text-right" style={{ width: 150,background:"none",border:"none",color:"black" }}>
                                                        {toggleContents} 
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        {countries.map(({ code, title,ext }) => (
                                                            <Dropdown.Item key={code} eventKey={code}><img alt={title} style={{width: "30px"}} src={countryFlagName(code).icon}/> {title} {ext}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                            </Dropdown> 
                                    </Col>
                                <Col lg="1" md="1" sm="1" xs="4">
                                    <span style={{fontSize:"1.7rem"}} className="text-right m-0 p-0">{phoneExt}</span>
                                </Col>
                                    <Col lg="4" md="4" sm="4" xs="12"  >
                                            <div className="group">
                                                        <input
                                                            type="text"
                                                            name="phone"
                                                            required
                                                            value={formik.values.phone}
                                                            onChange={formik.handleChange}
                                                            className="inputBenefy"
                                                        />
                                                                <span style={{color: "red"}}></span>
                                                                <span className="highlight"></span>
                                                                <span className="bar"></span>
                                                                <label className="labelInputBenefy">Teléfono</label>

                                                                {formik.errors.phone && formik.touched.phone && (
                                                                        <p className="inputwarningP">{formik.errors.phone}</p>
                                                                    )}
                                                </div> 
                                                       
                                    </Col>
                                </Row>
                                <Row  className="justify-content-center text-md-center text-lg-center text-left mt-3">
                                   
                                    <Col lg="4" md="4" sm="4" xs="12">
                                            <div className="group">
                                                                <input
                                                                    type="date"
                                                                    name="birthDate"
                                                                    required
                                                                    value={formik.values.birthDate}
                                                                    onChange={formik.handleChange}
                                                                    className="inputBenefy"
                                                                />
                                                                        <span style={{color: "red"}}></span>
                                                                        <span className="highlight"></span>
                                                                        <span className="bar"></span>
                                                                        <label className="labelInputBenefy">Fecha de nacimiento</label>

                                                                        {formik.errors.birthDate && formik.touched.birthDate && (
                                                                                <p className="inputwarningP">{formik.errors.birthDate}</p>
                                                                            )}
                                                        </div> 
                                                       
                                    </Col>
                                    <Col lg="4" md="4" sm="4" xs="12" >
                                    </Col>
                                </Row>
                                <Row className="justify-content-center text-md-center text-lg-center text-left mt-2">
                                    <Col lg="4" md="4" sm="4" xs="12">
                                        <span className="d-inline">Genéro:</span>
                                     <Dropdown
                                            className="mr-0 mr-md-1 mr-lg-1 d-inline"
                                                    onSelect={eventKey => {
                                                        setToggleContentsGenre(<> {genreList.find(x=>x.code===eventKey).title}  </>); 
                                                        setGenre(eventKey);
                                                    }}
                                                    >
                                                    <Dropdown.Toggle variant="secondary" id="dropdown-flags" className="text-right" style={{ width: 150,background:"none",border:"none",color:"black" }}>
                                                        {toggleContentsGenre} 
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        {genreList.map(({ code, title }) => (
                                                            <Dropdown.Item key={code} eventKey={code}>{title}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                            </Dropdown> 
                                    </Col>
                                    <Col as={Col}  lg="4" md="4" sm="4" xs="12" >
                                            <span className="d-inline">Estado Civil:</span>
                                             <Dropdown
                                                    className="mr-0 mr-md-1 mr-lg-1 d-inline"
                                                    onSelect={eventKey => {
                                                        setToggleContentsStatus(<> {statusList.find(x=>x.code===eventKey).title}  </>); 
                                                        setMaritalStatus(eventKey);
                                                    }}
                                                    >
                                                    <Dropdown.Toggle variant="secondary" id="dropdown-flags" className="text-right" style={{ width: 150,background:"none",border:"none",color:"black" }}>
                                                        {toggleContentsStatus} 
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {statusList.map(({ code, title }) => (
                                                            <Dropdown.Item key={code} eventKey={code}>{title}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                            </Dropdown> 
                                    </Col>
                                </Row >
                            
                            
                                <Row className="justify-content-center text-md-center text-lg-center text-left mt-5">
                                    <Col lg="4" md="4" sm="4" xs="12"  >
                                        <div className="group">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    required
                                                    value={formik.values.email}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Email</label>

                                                        {formik.errors.email && formik.touched.email && (
                                                                <p className="inputwarningP">{formik.errors.email}</p>
                                                            )}
                                        </div>        
                                    </Col>
                                    <Col lg="4" md="4" sm="4" xs="12" >
                                    </Col>
                                </Row>
                                <Row  className="justify-content-center text-md-center text-lg-center text-left mt-3">
                                <Col lg="4" md="4" sm="4" xs="12"  >
                                                        <div className="group">
                                                                <input
                                                                    type="password"
                                                                    name="password"
                                                                    required
                                                                    value={formik.values.password}
                                                                    onChange={formik.handleChange}
                                                                    className="inputBenefy"
                                                                />
                                                                        <span style={{color: "red"}}></span>
                                                                        <span className="highlight"></span>
                                                                        <span className="bar"></span>
                                                                        <label className="labelInputBenefy">Contraseña</label>

                                                                        {formik.errors.password && formik.touched.password && (
                                                                                <p className="inputwarningP">{formik.errors.password}</p>
                                                                            )}
                                                        </div> 
                                                       
                                    </Col>
                                    <Col lg="4" md="4" sm="4" xs="12"  >
                                                          <div className="group">
                                                                <input
                                                                    type="password"
                                                                    name="confirm_password"
                                                                    required
                                                                    value={formik.values.confirm_password}
                                                                    onChange={formik.handleChange}
                                                                    className="inputBenefy"
                                                                />
                                                                        <span style={{color: "red"}}></span>
                                                                        <span className="highlight"></span>
                                                                        <span className="bar"></span>
                                                                        <label className="labelInputBenefy">Confirmar contraseña</label>

                                                                        {formik.errors.confirm_password && formik.touched.confirm_password && (
                                                                                <p className="inputwarningP">{formik.errors.confirm_password}</p>
                                                                            )}
                                                        </div> 
                                                       
                                    </Col>
                                    
                                </Row >
                                <Row  className="justify-content-center text-md-center text-lg-center text-left mt-3">
                                    <Col lg="4" md="4" sm="4" xs="12">
                                        <span className="d-inline">Departamento:</span>
                                             <Dropdown
                                                    className="mr-0 mr-md-1 mr-lg-1 d-inline"
                                                    onSelect={eventKey => {
                                                        setToggleContentsStates(<> {states.find(x=>x.idState===eventKey).name}  </>); 
                                                        onStateChange(eventKey);
                                                        setFinalState(states.find(x=>x.idState===eventKey).name);
                                                    }}
                                                    >
                                                    <Dropdown.Toggle variant="secondary" id="dropdown-flags" className="text-right" style={{ width: 150,background:"none",border:"none",color:"black" }}>
                                                        {toggleContentsStates} 
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {states.map(({ idState, name }) => (
                                                            <Dropdown.Item key={idState} eventKey={idState}>{name}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                            </Dropdown> 
                                    </Col>
                                    <Col lg="4" md="4" sm="4" xs="12" >
                                        <span>Ciudad:</span>
                                             <Dropdown
                                                    className="mr-0 mr-md-1 mr-lg-1 d-inline"
                                                    onSelect={eventKey => {
                                                        setToggleContentsCities(<> {cities.find(x=>x.idCity===eventKey).name}  </>); 
                                                        setFinalcity(cities.find(x=>x.idCity===eventKey).name);
                                                    }}
                                                    >
                                                    <Dropdown.Toggle variant="secondary" id="dropdown-flags" className="text-right" style={{ width: 150,background:"none",border:"none",color:"black" }}>
                                                        {toggleContentsCities} 
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {cities.map(({ idCity, name }) => (
                                                            <Dropdown.Item key={idCity} eventKey={idCity}>{name}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                            </Dropdown> 
                                        </Col>
                                </Row >
                                 <Row className="justify-content-center text-center my-5">
                                    <Col>
                                        <Button style={ButtonStyle} className="orangeButton rounded-pill py-0" type="submit">INGRESAR</Button>
                                    </Col>
                                </Row>
                    </form>
                </Container>
            </Fade>
    </div>
  
        }
        </>
         )
}
