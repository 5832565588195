import React, { useEffect, useState } from 'react'
import { Button,Col, Container, Dropdown, Row } from 'react-bootstrap'
import { BenefyInput } from '../Components/FormElements/BenefyInput'
import { BenefyTextArea } from '../Components/FormElements/BenefyTextArea';
import MapContainer from '../Components/Others/MapContainer';
import { useFormik } from "formik";
import * as Yup from "yup";
const ButtonStyle={
  border:"none",
  width:"38%",
  fontSize:"20px"
}
const StartTimeList=[
  { code: 8, title: '8:00'},
  { code: 9, title: '9:00'},
  { code: 10, title: '10:00'},
  { code: 11, title: '11:00'},
  { code: 12, title: '12:00'},
  { code: 13, title: '13:00'},
  { code: 14, title: '14:00'},
  { code: 15, title: '15:00'},
  { code: 16, title: '16:00'}
] ;
const EndTimeList=[
  { code: 9, title: '9:00'},
  { code: 10, title: '10:00'},
  { code: 11, title: '11:00'},
  { code: 12, title: '12:00'},
  { code: 13, title: '13:00'},
  { code: 14, title: '14:00'},
  { code: 15, title: '15:00'},
  { code: 16, title: '16:00'},
  { code: 17, title: '17:00'}
] ;
export const DeliveryPage = ({dispatchPoints, maxDistance,country,deliveryAction}) => {
    const [direction, setDirection] = useState('');
    const [toggleContentsStartTime, setToggleDocumentStartTime] = useState(<>{StartTimeList[0].title}</>);
    const [toggleContentsEndTime, setToggleDocumentEndTime] = useState(<>{EndTimeList[0].title}</>);
    const [startTime, setStartTime] = useState(StartTimeList[0].title);
    const [endTime, setEndTime] = useState(EndTimeList[0].title);
    const [markerIsValid, setMarkerIsValid] = useState(false);
    const [coords, setCoords] = useState(null);
    const [pointFinal, setPointFinal] = useState(null);
    dispatchPoints=  dispatchPoints.map(item=>
      {
        return {
          name: item.name,
          location: { 
            lat: item.latitude,
            lng:  item.longitude
          },
          maxDistance:maxDistance,
         idPoint:item.idCommerceDispathPoint
    }
  });
  const CoordsIsValid=(valid)=>{
      setMarkerIsValid(valid);
    }
    // Create new Date instance
    var date = new Date();

    // Add a day
    date.setDate(date.getDate() + 1);
    const formik = useFormik({
      initialValues: {
        direction:"",
        personReceives: "",
        phone:"",
        email:"",
        deliveryDate:""
      },
      validationSchema: Yup.object({
       direction: Yup.string()
              .min(2, "2 carácteres como minimo")
              .max(150, "150 caracteres como máximo")
              .required("La dirección es requerida"),
        personReceives: Yup.string()
              .min(2, "2 carácteres como minimo")
              .max(15, "50 caracteres como máximo")
              .required("El nombre es requerido"),
         phone: Yup.string()
              .min(2, "2 carácteres como minimo")
              .max(15, "50 caracteres como máximo")
              .required("El télefono es requerido"),
          email:Yup.string().required('El correo es requerido').email("El formato del correo incorrecto"),
          deliveryDate:Yup.date().required('La fecha de entrega es requerida').min(date,"La fecha debe ser con al menos 1 día de diferencia")
          }),
      onSubmit: values => {
       let pointInf=dispatchPoints.find(x=> x.idPoint===pointFinal);
          values={
            ...values,
            startTime:startTime,
            endTime:endTime
          }
        deliveryAction(values,pointInf.idPoint,coords,pointInf.location);
      }
    });
    const coordsUserHandler=(latLong,idPoint)=>{
      console.log(latLong,idPoint)
        setCoords(latLong);
      setPointFinal(idPoint);
    }
    return (
      <form noValidate onSubmit={formik.handleSubmit}>
        <Container className="mt-5">
            <Row>
                <Col lg="12" md="12">
                <h1 className="mb-5">Detalle del delivery</h1>
                <div className="group">
                                                <textarea
                                                    type="text"
                                                    name="direction"
                                                    required
                                                    value={formik.values.direction}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                >
                                                  
                                                </textarea>
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Dirección</label>
                                                        {formik.errors.direction && formik.touched.direction && (
                                                                <p className="inputwarningP">{formik.errors.direction}</p>
                                                            )}
                                        </div>
               <MapContainer coordsHandler={coordsUserHandler} pointsList={dispatchPoints} country={country} validateMarker={CoordsIsValid}/>      
              {/*   <Button>
                    Aceptar
                </Button> */}
                </Col>
            </Row>
            <Row className="mt-5">
              <Col>
         {  markerIsValid &&   <Container>
                  <Row>
                    <Col>
                               <div className="group">
                                                <input
                                                    type="text"
                                                    name="personReceives"
                                                    required
                                                    value={formik.values.personReceives}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Persona que recibirá el pedido</label>
                                                        {formik.errors.personReceives && formik.touched.personReceives && (
                                                                <p className="inputwarningP">{formik.errors.personReceives}</p>
                                                            )}
                                        </div> 
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="group">
                                                  <input
                                                      type="text"
                                                      name="phone"
                                                      required
                                                      value={formik.values.phone}
                                                      onChange={formik.handleChange}
                                                      className="inputBenefy"
                                                  />
                                                          <span style={{color: "red"}}></span>
                                                          <span className="highlight"></span>
                                                          <span className="bar"></span>
                                                          <label className="labelInputBenefy">Télefono de contacto</label>
                                                          {formik.errors.phone && formik.touched.phone && (
                                                                  <p className="inputwarningP">{formik.errors.phone}</p>
                                                              )}
                                          </div> 
                    </Col>
                    <Col>
                    <div className="group">
                                                <input
                                                    type="text"
                                                    name="email"
                                                    required
                                                    value={formik.values.email}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Correo electrónico</label>
                                                        {formik.errors.email && formik.touched.email && (
                                                                <p className="inputwarningP">{formik.errors.email}</p>
                                                            )}
                                        </div> 
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    <div className="group">
                                                <input
                                                    type="date"
                                                    name="deliveryDate"
                                                    required
                                                    value={formik.values.deliveryDate}
                                                    onChange={formik.handleChange}
                                                    className="inputBenefy"
                                                />
                                                        <span style={{color: "red"}}></span>
                                                        <span className="highlight"></span>
                                                        <span className="bar"></span>
                                                        <label className="labelInputBenefy">Fecha de entrega</label>
                                                        {formik.errors.deliveryDate && formik.touched.deliveryDate && (
                                                                <p className="inputwarningP">{formik.errors.deliveryDate}</p>
                                                            )}
                                        </div> 
                     
                    </Col>
                  </Row>
                  <Row>
                <Col>
                       <Dropdown
                                        className="mx-0 mr-md-1 mr-lg-1 d-inline"
                                        onSelect={eventKey => {
                                            setToggleDocumentStartTime(<> {StartTimeList.find(x=>x.code===parseInt(eventKey)).title}  </>); 
                                            setStartTime(StartTimeList.find(x=>x.code===parseInt(eventKey)).title);
                                        }}
                                        >
                                        <Dropdown.Toggle variant="secondary" id="dropdown-flags" className="text-center" style={{ width: 150,background:"none",border:"none",color:"black" }}>
                                            {toggleContentsStartTime} 
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {StartTimeList.map(({ code, title }) => (
                                                <Dropdown.Item key={code} eventKey={code}>{title}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                </Dropdown>
                </Col>
                <Col>
                       <Dropdown
                                        className="mx-0 mr-md-1 mr-lg-1 d-inline"
                                        onSelect={eventKey => {
                                            setToggleDocumentEndTime(<> {EndTimeList.find(x=>x.code===parseInt(eventKey)).title}  </>); 
                                            setEndTime(EndTimeList.find(x=>x.code===parseInt(eventKey)).title);
                                        }}
                                        >
                                        <Dropdown.Toggle variant="secondary" id="dropdown-flags" className="text-center" style={{ width: 150,background:"none",border:"none",color:"black" }}>
                                            {toggleContentsEndTime} 
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {EndTimeList.map(({ code, title }) => (
                                                <Dropdown.Item key={code} eventKey={code}>{title}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                </Dropdown>
                </Col>
              </Row>
              <Row className="justify-content-center mt-5 text-center">
                    <Col>
                    <Button variant="primary"  type="submit" className="text-center orangeButton" style={ButtonStyle} >
                                              Aceptar
                  </Button>
                    </Col>
               </Row>
            </Container>
        
         }    
          </Col>
            </Row>
            
        </Container>
        </form>
    )
}
