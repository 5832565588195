import { setTokenUser } from "../Request/setTokenUser";
import { getCurrentUserId } from "./firebaseHelper";
import { PostRequest } from "./requestHelper";
import { urlRequest } from "./urlRequest";
import moment from 'moment'
export const SetUserDataLogin=async(user,country)=>{
    const {urlMicroAppMovil}=urlRequest();

   let resultData={
        message:"",
        isOk:false
    }
    
      let body={
          "country":country.country,
          "idUserFirebase":getCurrentUserId()
          };
      
let resultUserInfo= await PostRequest(body,`${urlMicroAppMovil}User/GetUser`).then(async result=>
          {
              if(result.code===200){
                const tokenR= setTokenUser();

                if(tokenR.error){
                  resultData.message="No se pudo obtener el token";
                  return resultData;
              }
                  localStorage.setItem("Nameus",result.data.names);
                  resultData.message="Ingreso exitoso"
                  resultData.isOk=true;
                return resultData;
              }
              
              if(result.code===400)
              {
                const actualDate= moment().format("YYYY-MM-DD");
                const bodyUserRegister={
                    "idUserFirebase": getCurrentUserId(),
                    "country":country.country,
                    "names": user.displayName,
                    "countryCode": country.country,
                    "email": user.email,
                    "tycDate": actualDate,
                    "flagTyc": true,
                    "actualCountry": country.country
                }
              await  PostRequest(bodyUserRegister,`${urlMicroAppMovil}User/SaveUser`).then(result=>
                    {
                   
                        if(result.isSuccess){
                          resultData.message="Ingreso exitoso"
                          resultData.isOk=true;
                          return result;
                        }else{
                          resultData.message="No se pudo registrar el usuario";
                          return resultData;
                        }
                       
                    }
                    );
              }
              resultData.message="Error al obtener el usuario"
                 return resultData;
              
          }
      );
    
    return resultData;
}