import React, { useEffect, useState } from 'react'
import { Zoom } from 'react-awesome-reveal'
import { Col, Container, Row } from 'react-bootstrap'
import {principalOrange} from '../../Style/Colors'
import '../../Style/Css/FullLoader.css'
const backOrange={
 //   background:principalOrange,
    height:"100vh"
};
const orangeText={
    color:principalOrange
}
 const FullScreenLoader = () => {
    const [show, setShow] = useState(false)
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300)
      return () => {
        clearTimeout(timeout)
      }
    }, [])
    return (
        <>
        {show &&
          <Zoom  
          
          >
          <Container fluid style={backOrange}>
               <Row className="h-50 justify-content-center text-center">
                   <Col>
                
                   <h2 style={{...orangeText,fontSize:"1.5rem"}}  className="WhiteSubtitlePage mt-5 pt-5">Espera un momento por favor</h2>
                   </Col>
               </Row>
             <Row className="">
                
                 <Col className="my-auto">
                    
                 <div id="loader">
                    
                    <div id="shadow"></div>
                    <div id="box"></div>
                </div>
                 </Col>
             </Row>
            </Container>
            </Zoom>
        }
        </>

      
                
           
    )
}

export default FullScreenLoader