import { lazy, Suspense, useEffect, useState } from 'react';
import { FooterBenefy } from '../Components/Sections/FooterBenefy';
import { HeaderBenefy } from '../Components/Sections/HeaderBenefy';
import '../Style/Css/TransitionAnimation.css';
import { BrowserRouter as Router,
    Switch,
    Route,
    Redirect
  } from  "react-router-dom";
import  "../Style/Css/Header.css";
import FullScreenLoader from '../Components/Loading/FullScreenLoader';
import { principalOrange } from '../Style/Colors';
import JoinToBenefyPage from './JoinToBenefyPage';
import { LoginPage } from './LoginPage';
import { RegisterPage } from './RegisterPage';
import fireApp from '../firebase';
import { CartCheckoutPage } from './CartCheckoutPage';
import { useToasts } from 'react-toast-notifications';
import { UserProfilePage } from './UserProfilePage';
import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { GetUserData } from '../helpers/functionsHelpers';
import { PaymentSuccess } from '../Components/Modals/PaymentSuccess';
import { urlRequest } from '../helpers/urlRequest';
import { ModalPresentDetail } from "../Components/Modals/ModalPresentDetail";
import { DeliveryPage } from './DeliveryPage';

const ArticlesPage=lazy( ()=> import('./ArticlesPage'))
const GiftCardPage=lazy( ()=> import('./GiftCardPage'))
const WhoWeArePage=lazy( ()=> import('./WhoWeArePage'))
const WhoWeAreHeader=lazy( ()=> import('../Components/Sections/WhoWeAreHeader'))
const OurProductsHeader=lazy( ()=> import('../Components/Sections/OurProductsHeader'))
const OurProductsPage=lazy( ()=> import('./OurProductsPage'))
const DownloadHeader=lazy( ()=> import('../Components/Sections/DownloadHeader'))
const DownloadPage=lazy( ()=> import('./DownloadPage'))
const ContactPage=lazy( ()=> import('./ContactPage'))
const CouponDetail=lazy( ()=> import('../Components/Coupons/CouponDetail'))
const CommercePage=lazy( ()=> import('./CommercePage'))
const BackOrange={
    background:principalOrange
}

export const PrincipalPage=()=>{
    const {urlMicroNotificaciones}=urlRequest()
    var selectedCountry=localStorage.getItem('ActualCountry')||'BO';
    const [user, setUser] = useState("");
    const [country, setCountry] = useState(selectedCountry);
    const [paymentModal, setPaymentModal] = useState(false);
    const [notificationBody, setNotificationBody] = useState(null);
    const [idPresentOrder, setIdPresentOrder] = useState(null);
    const [showPresentModal, setShowPresentModal] = useState(false);
    const [presentCode, setPresentCode] = useState('')
    const { addToast } = useToasts();
    function Iframe(props) {
        return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
      }
    useEffect(async() => {
        if( user){
            const userDetail=await GetUserData(country,user.uid);
            if(userDetail){
              setUpSignalRConnection(userDetail.user.idUser).then(() => {
              });
            }
          }
    }, [user]);

    useEffect(() => {
       localStorage.setItem('ActualCountry',country)
       let isAdded=localStorage.getItem('added') || "0";
       if(isAdded==="1"){
            addToast(`Producto añadido al carrito`, {
                appearance: 'success',
                autoDismiss: true
            
            });
            localStorage.setItem('added',"0");
         }

    }, [])

    const setUpSignalRConnection = async (boardId) => {
        const connection = new HubConnectionBuilder()
          .withUrl(`${urlMicroNotificaciones}notificationhub`)
          .withAutomaticReconnect()
          .build();
    
        connection.on('Message', (message) => {
          console.log('Message', message);
        });
   
    
        try {
          await connection.start();
        } catch (err) {
          console.log(err);
        }
    
        if (connection.state === HubConnectionState.Connected) {
          connection.invoke('SubscribeToGroup', boardId).catch((err) => {
            return console.error(err.toString());
          });
          connection.on('SuccessPay', (message) => {
            console.log(message);    
            setNotificationBody(message);
            setPaymentModal(true);
          });
        }
    
        return connection;
      };

    const countryCallback=(Ncountry)=>{
        localStorage.setItem('ActualCountry',Ncountry);
        setCountry(Ncountry);
        
    }
    const authListener=()=>{
        fireApp.auth().onAuthStateChanged(user=>{
                if(user){
                    setUser(user);
                }else{
                   setUser("")
                }
        });
    }
    useEffect(() => {
        authListener();
    }, [])
    const showModalDetail=(idOrderPresent,Code)=>{
        setIdPresentOrder(idOrderPresent);
        setShowPresentModal(true);
        setPaymentModal(false);
        setPresentCode(Code);
    }
    return(
        <>
        <Router forceRefresh={true}> 
                <header className="NavBarHeader pt-4">
                    <Suspense fallback={BackOrange}>
                    <HeaderBenefy
                        countryEvent={countryCallback}
                        Initialcountry={country}
                        user={user}
                    />
                    </Suspense>
                    <Suspense fallback={<FullScreenLoader/>}>
                    <Switch>
                    DownloadHeader
                      <Route path='/WhoWeArePage' component={WhoWeAreHeader} />                      
                    <Route path='/OurProductsPage' component={OurProductsHeader}/>
                    <Route path='/DownloadPage' component={DownloadHeader}/>
                    <Route path='/JoinToBenefy' render={(props)=>(
                        <JoinToBenefyPage
                        country={country}
                     /> 
                    )} />   
                    </Switch>
                    </Suspense>
                </header>
                <Suspense fallback={<FullScreenLoader/>}>
                 <main>
                     
                
                 <Switch>
                  
                  <Route
                        exact
                        path="/"
                        render={() => {
                            return  <Redirect to="/Articles" /> 
                        }}
                    /> 
                     
                     <Route exact path='/Articles'                        
                      render={(props)=>(
                        <ArticlesPage
                        {...props}
                        country={country}
                     /> 
                    )}  >
                    </Route>
                <Route path='/GiftCards' render={(props)=>(
                        <GiftCardPage
                        country={country}
                     /> 
                    )} /> 
                <Route path='/WhoWeArePage' render={(props)=>(
                        <WhoWeArePage
                        country={country}
                     /> 
                    )} />   
                    <Route path='/OurProductsPage' render={(props)=>(
                        <OurProductsPage
                        country={country}
                     /> 
                    )} /> 
                    <Route path='/DownloadPage' render={(props)=>(
                        <DownloadPage
                        country={country}
                     /> 
                    )} /> 
                      <Route path='/ContactPage' render={(props)=>(
                        <ContactPage
                        country={country}
                     /> 
                    )} /> 
                    <Route path='/ProductDetail/:country/:type/:idProduct'  component={CouponDetail}/>   
                    <Route path='/CommercePage' render={(props)=>(
                        <CommercePage
                        country={country}
                     /> 
                    )} />  
                       <Route path='/CommercePage' render={(props)=>(
                        <CommercePage
                        country={country}
                        /> 
                        )} />
                      <Route path='/Login' render={(props)=>(
                        <LoginPage
                        country={country}
                        /> 
                        )} />   
                        <Route path='/Register' render={(props)=>(
                        <RegisterPage
                        country={country}
                        /> 
                        )} /> 
                        <Route path='/CheckoutOrder/:type?' render={(props)=>(
                        <CartCheckoutPage
                        country={country}
                        /> 
                        )} />
                        <Route path='/UserProfile' render={(props)=>(
                            <UserProfilePage
                            country={country}
                            />
                        )}/>
                      <Route path='/Delivery' render={(props)=>(
                                                  <DeliveryPage
                                                  country={country}
                                                  />
                                              )}/>
              </Switch> 
              
           
                </main>
                
                <footer  className='footer mt-auto py-3 ' >
                   <FooterBenefy
                        country={country}
                   />
                 </footer> 
                 <PaymentSuccess notificationBody={notificationBody}  showModal={paymentModal} country={country} methodAction={showModalDetail}/>
                 <ModalPresentDetail Code={presentCode} showModal={showPresentModal} country={country} idOrderPresent={idPresentOrder}/>
                 </Suspense>
                
        </Router>
      
        </>
    )
}