import fireApp from "../firebase";

let result={
    isSuccess:false,
    message:"",
    passError:"",
    emailError:""
}

export const LoginUser=async(email,password)=>{

    try {   
        result.isSuccess=true;
        result.message="Bienvenido"
       await fireApp.auth().signInWithEmailAndPassword(email,password)
        .catch(function(err){
            result.isSuccess=false;
            switch(err.code){
                case "auth/invalid-email":
                    result.message="El correo no es válido"
                    break;
                case "auth/user-disabled":
                    result.message="El usuario se ha deshabilitado"
                    break;
                case "auth/user-not-found":
                    result.message="No se encontró ningún usuario asociado a este correo";
                    break;
                case "auth/wrong-password":
                    result.message="La contraseña es incorrecta";
                break;
                default:
                    result.message="Ocurrió un error desconocido al intentar iniciar sesión";
                    break;
            }
        });
    } catch(error) {
        result.message="Ocurrió un error desconocido";
        result.isSuccess=false;
    }
    return result;
}
export  const RegisterUser=async(email,password)=>{
    result.isSuccess=false;
    result.message="Usuario registrado con éxito"
    try {
        await fireApp.auth().createUserWithEmailAndPassword(email,password).then(function(user){
            console.log(user);
            result.isSuccess=true;
        })
        .catch(function(err){
            switch(err.code){
                case "auth/email-already-in-use":
                    result.message="El correo ya se encuentra registrado"
                    break;
                case "auth/invalid-email":
                    result.emailError="El correo no es válido"
                    break;
                case "auth/user-not-found":
                    result.message=err.message;
                    break;
                case "auth/weak-password":
                    result.message=err.message;
                break;
                default:
                    result.message="Error desconocido"
                break;
            }
        });
    } catch(error) {
        result.message=error.message;
    }
    return result;
}
export  const LogOut=()=>{
    fireApp.auth().signOut()
}


export const getCurrentUsername=() =>{
    console.log(fireApp.auth());
    return fireApp.auth().currentUser && fireApp.auth().currentUser.displayName
}
export const getCurrentUserId=() =>{
    return fireApp.auth().currentUser && fireApp.auth().currentUser.uid
}


