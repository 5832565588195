import React from 'react'

export const EmailIcon = ({ClassName,Width,Height})=> {
    return (
        <svg className={ClassName} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="19" height="20" viewBox="0 0 19 20">
        <image id="Email" width={Width} height={Height} xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAUCAYAAABvVQZ0AAABpElEQVQ4ja3UTYgNUBQH8J83PhYWE81yXjZKKcUCzW6yUPIxlMhiFiQWFKmxsrAgyoKxoGStmdn4TjayUxJKKUvGcjIps0A+OtN503V7472R/+r2P+f/v+fec+9ZNHOsXxuswhFsw1osw1e8xSPcwPtaVpstxTmcxJJ2uyS+4wrO4FuLbBQJvXiKkQ5GMj6S+b21WQ/uYKCDSY2B1PWUZrHL4AKNWhhM/eydrcAklmfwA351adSfVc2guRjDhVHgOi52YbQX47kO/XAcc2eR8AaHcLyD0XaM4nnB7Qiz9QXxCVtwCgfnMYr4TezBu4LfEGZ9VfJHbMVZ7K9im3EL+6qqAn0N7fEz2avYlet1uB9Nm+/s0YCpqrro0GOcxws8xGqcxmF8wQSGKq+pqOx1QaxMo2v5/15mgzbiKO7hSZrexppC+6qRpbcQRxnD5YKL6g7gbsFFtSewqeAetHu0k8WddUIzf9Hco53OSXEhhc0ujUqEfrrVzUs5Af4FoQv93Ef/gd14tkCzyA9d6P+YZ59zAsQuMfz+hohHXuSHbhb/b2zjN1dUWwc5HGiUAAAAAElFTkSuQmCC"/>
      </svg>
      
    )
}
