import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../../Style/Css/Animations.css'
const LoadingSecundary = ({textTitle}) => {
    return (
        <Container fluid>
          {
              textTitle && <h1 className="mt-5">{textTitle}</h1>
          }
            <Row className="justify-content-center text-center">
                <Col>
                        <div className="loadingio-spinner-pulse-fb0b2e07ls"><div className="ldio-ot29bi80foj">
                    <div></div><div></div><div></div>
                    </div></div>
                </Col>
            </Row>
         
        </Container>
    )
}
export default LoadingSecundary