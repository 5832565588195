import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { Button, Col, Container, Row } from 'react-bootstrap'
import '../Style/Css/JoinToBenefyPage.css'
import logo_blanco from '../Style/Img/logo_blanco.svg' 
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { facebookLogin, GoogleLogin } from '../helpers/firebaseHelper'
import FacebookLogo from '../Style/Img/JoinToBenefyPage/logoFacebook.png'
import GoogleLogo from '../Style/Img/JoinToBenefyPage/googleLogo.png'
import FacebookLogin from 'react-facebook-login';
import firebase from 'firebase'
import { SetUserDataLogin } from '../helpers/socialMediaHelper'
import { useToasts } from 'react-toast-notifications'
const TitleStyleTitle=styled.h1`
    color:white;
    font-size:1.5rem;
    font-weight:100 !important;
`
const SubTitleStyleTitle={
    fontSize:"1.1rem"
}
const ButtonStyle={
    width: "80%",
    border:"none",
    fontWeight:"100",
    height:"3rem"
}

const JoinToBenefyPage = (country) => {
    const history=useHistory();
    const { addToast } = useToasts();
    const responseFacebook= (response)=>{
        var provider = new firebase.auth.FacebookAuthProvider();
        firebase
                .auth()
                .signInWithPopup(provider)
                .then(async(result) => {
                    /** @type {firebase.auth.OAuthCredential} */
                    var credential = result.credential;

                    // The signed-in user info.
                    var user = result.user;
                    var setUser = await SetUserDataLogin(user,country);
                    if(setUser.isOk){

                          addToast("Proceso exitoso", {
                            appearance: 'success',
                            autoDismiss: true
                          });
                          history.replace('/');
                    }else{
                        addToast("Ocurrió un error en el proceso de ingreso", {
                            appearance: 'error',
                            autoDismiss: true
                          });
                    }
                    // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                    var accessToken = credential.accessToken;

                    // ...
                })
                .catch((err) => {
    // Handle Errors here.
    var message;

    switch(err.code){
        case "auth/invalid-email":
            message="El correo no es válido"
            break;
        case "auth/user-disabled":
            message="El usuario se ha deshabilitado"
            break;
        case "auth/user-not-found":
            message="No se encontró ningún usuario asociado a este correo";
            break;
        case "auth/wrong-password":
            message="La contraseña es incorrecta";
        break;
        default:
            message="Ocurrió un error desconocido al intentar iniciar sesión";
            break;
    }

    // ...
  });
    }
    const responseGoogle= (response)=>{
        var provider = new firebase.auth.GoogleAuthProvider();
        firebase
                .auth()
                .signInWithPopup(provider)
                .then(async(result) => {
                    /** @type {firebase.auth.OAuthCredential} */
                    var credential = result.credential;

                    // The signed-in user info.
                    var user = result.user;
                    var setUser = await SetUserDataLogin(user,country);
                    if(setUser.isOk){

                          addToast("Proceso exitoso", {
                            appearance: 'success',
                            autoDismiss: true
                          });
                          history.replace('/');
                    }else{
                        addToast("Ocurrió un error en el proceso de ingreso", {
                            appearance: 'error',
                            autoDismiss: true
                          });
                    }
                    // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                    var accessToken = credential.accessToken;

                    // ...
                })
                .catch((err) => {
    // Handle Errors here.
    var message;

    switch(err.code){
        case "auth/invalid-email":
            message="El correo no es válido"
            break;
        case "auth/user-disabled":
            message="El usuario se ha deshabilitado"
            break;
        case "auth/user-not-found":
            message="No se encontró ningún usuario asociado a este correo";
            break;
        case "auth/wrong-password":
            message="La contraseña es incorrecta";
        break;
        default:
            message="Ocurrió un error desconocido al intentar iniciar sesión";
            break;
    }

    // ...
  });
    }
    return (
        <Fade>
        <Container>
            <Row className="justify-content-md-center text-center pb-5">
                <Col  lg="9">
                  <div className="">
                    <img alt="Benefy" src={logo_blanco} className="JoinToBenefyPageLogoSize"/>
                    <TitleStyleTitle className="my-3"  style={{fontWeight:"100!important"}}>
                       <span style={{fontWeight:"bold"}} >¡Empieza a ahorrar</span> con tus comercios favoritos!
                    </TitleStyleTitle>
                    <br/>
                    <Button style={ButtonStyle} className="whiteButton rounded-pill py-0"  onClick={()=>{history.push('/register')}}>CREAR CUENTA</Button> 
                    <p className="WhiteSubtitlePage JoinToBenefyPageLineText font-weight-100 my-5 px-0 px-md-5 px-lg-5 mx-md-5 mx-lg-5 mx-0" style={SubTitleStyleTitle}>
                        ¿Ya tienes una cuenta?
                    </p>
                    <Button style={ButtonStyle} className="orangeButton rounded-pill py-0 mb-5" onClick={()=>{history.push('/login')}}>INGRESAR</Button>
           
                  </div>
        
                </Col>
            </Row>
             <Row>
                <Col>
                <p className="WhiteSubtitlePage  text-center font-weight-100 px-0 px-md-5 px-lg-5 mx-md-5 mx-lg-5 mx-0" style={SubTitleStyleTitle}>
                        O ingresa con redes sociales
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-md-center text-center pb-5">
                <Col className="text-right">
                    {/* 
                <FacebookLogin
    appId="506415663975671"
    autoLoad={true}
    fields="name,email,picture"
    callback={responseFacebook} />*/}
                                                   <a onClick={responseFacebook}  className="linkTextHeader pt-2  text-center my-md-0 my-lg-0 my-3" activeClassName='is-active'>
                                                            
                                                            <img src={FacebookLogo} alt="Facebook login"  style={{width:"3.4rem"}} title="Ingresar con Facebook"/>
                                                </a> 
                
                </Col>
                <Col className="text-left">
                <a onClick={responseGoogle} className="linkTextHeader pt-2  text-center my-md-0 my-lg-0 my-3" activeClassName='is-active'>
                                                            
                                                            <img src={GoogleLogo} alt="Google login"  style={{width:"3.4rem"}} title="Ingresar con Google"/>
                                                </a> 
                
                </Col>
            </Row> 
        </Container>
        </Fade>
    )
}
export default JoinToBenefyPage