import React, { useState } from 'react';
import { Circle, GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { countryDefaultCoords } from '../../helpers/countryInfo';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
const MapContainer = ({pointsList,country,validateMarker,coordsHandler}) => {
  const [ id, setId ] = useState(0);
   const [ markers, setMarkers ] = useState([]);
   const [ drawMarker, setDrawMarker ] = useState(true);
   const [pointSelected, setPointSelected] = useState(null)
   const [finalCoord, setFinalCoord] = useState(null)
   const { addToast } = useToasts();
   const [userCoords , setUserCoords] = useState(null);
   const mapStyles = {        
    height: "100vh",
    width: "100%"};

    const ButtonStyle={
      border:"none",
      width:"38%",
      fontSize:"20px"
  }
  const defaultCenter = finalCoord || pointsList[0].location;
 
    const addMarker = (coords) => {
      setId((id)=>id+1);
      setMarkers((markers) => markers.concat([{coords, id}]) )
      setDrawMarker(false);
      setFinalCoord(coords);
      setUserCoords(coords);
    }
    function arePointsNear(checkPoint, centerPoint, km) {
      var ky = 40000 / 360;
      var kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
      var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
      var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
      return Math.sqrt(dx * dx + dy * dy) <= km;
    }
    const validateIsInRange=(actualCoords)=>{
      pointsList.forEach(element => {
          
          if(arePointsNear(actualCoords,element.location,element.maxDistance))
          {
            addToast(`Completa la información`, {
              appearance: 'success',
              autoDismiss: true
            });
            validateMarker(true);
            coordsHandler(actualCoords,element.idPoint);
          }else{
            addToast(`El punto de entrega esta fuera de la zona de cobertura, arrastralo hacia una zona valida`, {
              appearance: 'warning',
              autoDismiss: true
            });
            validateMarker(false);
          }
      });
      
    }
    const addNewMarker=(e)=>{
   
      if(drawMarker) {
        
         addMarker(e.latLng.toJSON())
         setUserCoords(e.latLng.toJSON());
         validateIsInRange(e.latLng.toJSON());
         }
    }
  return (
    <Container>
      <Row>
        <Col>
        <LoadScript
       googleMapsApiKey="AIzaSyA1iT6ZV4EiFdQCafGk6AFzn18bci0-ZKU">
        <GoogleMap 
       onClick={addNewMarker}
          mapContainerStyle={mapStyles}
          zoom={11}
          center={defaultCenter}
        >
          {markers ? (
        markers.map((marker) => {
          return (
            <Marker
              key={marker.id}
              draggable={true}
              position={marker.coords}
              onDragEnd={e => 
                {
                marker.coords = e.latLng.toJSON();
                setFinalCoord(e.latLng.toJSON())
                setUserCoords(e.latLng.toJSON())
                validateIsInRange(e.latLng.toJSON());
              }} 
            />
          )
        })
      ) : null }
             {
            pointsList.map(item => {
              return (
                <Circle
                key={item.idPoint}
                  radius={item.maxDistance*1000}
                  center={item.location}
                  onMouseover={() => console.log('mouseover')}
                  onClick={addNewMarker}
                  data-index={item.idPoint}
                  onMouseout={() => console.log('mouseout')}
                  strokeColor='transparent'
                  strokeOpacity={0}
                  strokeWeight={5}
                  fillColor='#f97716'
                  fillOpacity={0.2}
                  
                />
                 
              )
            })
         }
        </GoogleMap>
     </LoadScript>
      </Col>
      </Row>
      <Row>
        <Col>
          
        </Col>
      </Row>
    
     </Container>
  )
}
export default MapContainer;