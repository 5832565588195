
import React, { useRef, useState } from 'react'
import { Button, Col, Container, Form, Nav, Row, Tab } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications';
import { BenefyInput } from '../Components/FormElements/BenefyInput';
import { InsuredInfo } from '../Components/Sections/InsuredInfo'
import { useFormik } from "formik";
import * as Yup from "yup";
import { InsuredInforCovid } from '../Components/Sections/InsuredInforCovid';
import { InsuredInforPets } from '../Components/Sections/InsuredInforPets';
const ButtonStyle={
    width: "12rem",
    border:"none",
    fontWeight:"100",
    height:"3rem"
}
export const InsuredContract = ({country,onContractSave,elementType}) => {
   // const covidDescription = useRef();
    const { addToast }=useToasts();
    const [actualTab, setActualTab] = useState(0)
    const [key, setKey] = useState('0');
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [tabValid, setTabValid] = useState(false);
    const [firstQuestion, setFirstQuestion] = useState(true);
    const [secondQuestion, setSecondQuestion] = useState(true);
    const [thirdQuestion, setThirdQuestion] = useState(true);
    const [fiveQuestion, setFiveQuestion] = useState(false);
    const [insuredInfoDetail, setInsuredInfoDetail] = useState({});
    const [covidQuestion, setcovidQuestion] = useState(false);
    const [covidDescription, setCovidDescription] = useState("");
    const UserInfoActionMethod=(insuredData,Kvalid)=>{
        setTabValid(Kvalid);
        EnableTab("1")
        setActualTab(1);
        setInsuredInfoDetail(insuredData);
    }
    const EnableTab=(nextTab)=>{
        setTabValid(true);
        setKey(nextTab);
        setTabValid(false);
    }
    const Question1Event=()=>{
        if(firstQuestion)
        {
            addToast(`Para poder aplicar, la respuesta debe ser negativa.`, {
                appearance: 'warning',
                autoDismiss: true
              }); 
              return;  
        }
            EnableTab("2");
            setActualTab(2);

        
    }
    const Question2Event=()=>{
        if(secondQuestion)
        {
            addToast(`Para poder aplicar, la respuesta debe ser negativa.`, {
                appearance: 'warning',
                autoDismiss: true
              }); 
              return;  
        }                                        
        EnableTab("3");
        setActualTab(3);
    }
    const Question3Event=()=>{
        if(thirdQuestion)
        {
            addToast(`Para poder aplicar, la respuesta debe ser negativa.`, {
                appearance: 'warning',
                autoDismiss: true
              }); 
              return;  
        }                                               
        EnableTab("4");
        setActualTab(4);
    }
    const Question4Event=()=>{                 
        EnableTab("5");
        setActualTab(5);
    }
    const Question5Event=()=>{
        if(!fiveQuestion){
            addToast(`Para poder aplicar, la respuesta debe ser positiva.`, {
                appearance: 'warning',
                autoDismiss: true
              }); 
              return; 
        }                                                         
        EnableTab("6");
        setActualTab(6);
        const contractData={
            ...insuredInfoDetail,
            weight:weight,
            height:height,
            question1:firstQuestion,
            descriptionQuestion1:"",
            question2:secondQuestion,
            descriptionQuestion2:"",
            question3:thirdQuestion,
            descriptionQuestion3:"",
            question5:fiveQuestion,
            descriptionQuestion5:"",
            beneficiaryPercentage:100,
            doctorName:"",
            doctorAddress:""
        }
        onContractSave(contractData);
    }
    const formikWeight = useFormik({
        initialValues: {
            weight: "",
            height:""
        },
        validationSchema: Yup.object({
            weight: Yup.number().required("El peso es requerido").positive("El peso debe ser mayor a cero"),
            height: Yup.number().required("La altura es requerida").positive("La altura debe ser mayor a cero")
        }),
        onSubmit: values => {
            setHeight(values.height);
            setWeight(values.weight);
            Question4Event();
        }
      });
      const QuestionCovidEvent=()=>{
          if(covidQuestion){
                if(covidDescription===""){
                    addToast(`Es necesario especificar la información solicitada`, {
                        appearance: 'warning',
                        autoDismiss: true
                      }); 
                      return; 
                }
          }
          const contractData={
            ...insuredInfoDetail,
            weight:0,
            height:0,
            question1:firstQuestion,
            descriptionQuestion1:"",
            question2:secondQuestion,
            descriptionQuestion2:"",
            question3:thirdQuestion,
            descriptionQuestion3:"",
            question5:fiveQuestion,
            descriptionQuestion5:"",
            beneficiaryPercentage:100,
            doctorName:"",
            doctorAddress:"",
        }
        onContractSave(contractData);
      }
    return (
        <Container className="my-5">
            <Row>
                <Col>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="0"
                    activeKey={key}
                    onSelect={(k) => {
                        if(tabValid || actualTab>=parseInt(k))
                        {
                         setKey(k)
                        }
                    }}
                    >
                            <Row>
                                    <Col sm={3}>
                                        <Nav variant="pills" className="flex-column" >
                                        <Nav.Item >
                                            <Nav.Link eventKey="0" className="BlueAItems">Información personal</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="1" className="BlueAItems">Pregunta 1</Nav.Link>
                                        </Nav.Item>
                                        {
                                        elementType!==11
                                        &&
                                        <>
                                        <Nav.Item>
                                            <Nav.Link eventKey="2"  className="BlueAItems">Pregunta 2</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="3"  className="BlueAItems">Pregunta 3</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="4"  className="BlueAItems">Pregunta 4</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="5"  className="BlueAItems">Pregunta 5</Nav.Link>
                                        </Nav.Item>
                                        </>
                                        }
                                        </Nav>
                                    </Col>
                                    <Col sm={9}>
                                        {
                                            elementType===11
                                            ?
                                            <Tab.Content>
                                                 <Tab.Pane eventKey="0">
                                                    {elementType===5?
                                                        <InsuredInfo elementType={elementType} UserInfoAction={UserInfoActionMethod} country={country}/>
                                                                :
                                                            elementType===6?
                                                            <InsuredInforPets  elementType={elementType} UserInfoAction={UserInfoActionMethod} country={country}/>
                                                            :
                                                            <InsuredInforCovid  elementType={elementType} UserInfoAction={UserInfoActionMethod} country={country}/>}
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="1">
                                                        <p>¿Padece usted alguna de estas enfermedades: Cáncer,VIH SIDA, Diabetes, Enfermedades del Corazón, Insuficiencia Renal, Enfermedades Pulmonar Crónica Obstructiva?</p>
                                                        <Form.Group as={Row} className=" Justify-content-center text-center mt-5">
                                                            
                                                            <Col >
                                                                <Form.Check
                                                                type="radio"
                                                                label="Si"
                                                                name="formHorizontalRadios"
                                                                id="formHorizontalRadios2"
                                                                onClick={()=>setcovidQuestion(true)}
                                                                
                                                                />
                                                            
                                                            </Col>
                                                            <Col>
                                                            <Form.Check
                                                                type="radio"
                                                                label="No"
                                                                name="formHorizontalRadios"
                                                                id="formHorizontalRadios3"
                                                                onClick={()=>setcovidQuestion(false)}
                                                               
                                                                />
                                                                </Col>
                                                            </Form.Group>
                                                            <Row>
                                                                <Col>
                                                                    <p>En caso de constetación afirmativa, proporcione detalles:(fecha, tipo de enfermedad, duración, médico tratante):</p>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                        <div className="group">
                                                                            <input
                                                                                type="text"
                                                                                name="covidQuestin"
                                                                                required
                                                                                onChange={(e)=>setCovidDescription(e.currentTarget.value)}
                                                                                className="inputBenefy"
                                                                            />
                                                                                    <span style={{color: "red"}}></span>
                                                                                    <span className="highlight"></span>
                                                                                    <span className="bar"></span>
                                                                                    <label className="labelInputBenefy">Detalle</label>
                                                                       </div> 
                                                                </Col>
                                                            </Row>
                                                            <Row className="text-center">
                                                                <Col>
                                                                    <Button style={ButtonStyle} className="orangeButton rounded-pill py-0" onClick={()=>QuestionCovidEvent()} >Continuar</Button>
                                                                </Col>
                                                            </Row>
                                              </Tab.Pane>
                                             </Tab.Content>
                                            :
                                            <Tab.Content>
                                        <Tab.Pane eventKey="0">
                                            {elementType===5?
                                                <InsuredInfo elementType={elementType} UserInfoAction={UserInfoActionMethod} country={country}/>
                                                        :
                                                    elementType===6?
                                                    <InsuredInforPets  elementType={elementType} UserInfoAction={UserInfoActionMethod} country={country}/>
                                                    :
                                                    <InsuredInforCovid  elementType={elementType} UserInfoAction={UserInfoActionMethod} country={country}/>}
                                    </Tab.Pane>
                                        <Tab.Pane eventKey="1">
                                                <p>¿Ha padecido alguna enfermedad que le haya obligado a interrumpir su actividad profesional en los ultimos 10 años, durante mas de 15 días consecutivos?</p>
                                                <Form.Group as={Row} className=" Justify-content-center text-center mt-5">
                                                    
                                                    <Col >
                                                        <Form.Check
                                                        type="radio"
                                                        label="Si"
                                                        name="formHorizontalRadios"
                                                        id="formHorizontalRadios2"
                                                        onClick={()=>setFirstQuestion(true)}
                                                        checked={firstQuestion}
                                                        />
                                                    
                                                    </Col>
                                                    <Col>
                                                    <Form.Check
                                                        type="radio"
                                                        label="No"
                                                        name="formHorizontalRadios"
                                                        id="formHorizontalRadios3"
                                                        onClick={()=>setFirstQuestion(false)}
                                                        checked={!firstQuestion}
                                                        />
                                                        </Col>
                                                    </Form.Group>
                                                    <Row className="text-center">
                                                        <Col>
                                                              <Button style={ButtonStyle} className="orangeButton rounded-pill py-0" onClick={()=>Question1Event()} >Continuar</Button>
                                                        </Col>
                                                    </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="2">
                                                <p>¿Ha sufrido algún accidente o tiene alguna alteración física?</p>
                                                <Form.Group as={Row} className=" Justify-content-center text-center mt-5">
                                                    <Col>
                                                        <Form.Check
                                                        type="radio"
                                                        label="Si"
                                                        name="formHorizontalRadios"
                                                        id="formHorizontalRadios2"
                                                        onClick={()=>setSecondQuestion(true)}
                                                        checked={secondQuestion && true}
                                                        />
                                                    
                                                    </Col>
                                                    <Col>
                                                    <Form.Check
                                                        type="radio"
                                                        label="No"
                                                        name="formHorizontalRadios"
                                                        id="formHorizontalRadios3"
                                                        onClick={()=>setSecondQuestion(false)}
                                                        checked={!secondQuestion}
                                                        />
                                                        </Col>
                                                    </Form.Group>
                                                    <Row className="text-center">
                                                        <Col>
                                                              <Button style={ButtonStyle} className="orangeButton rounded-pill py-0" onClick={()=>Question2Event()}  >Continuar</Button>
                                                        </Col>
                                                    </Row>
                                         </Tab.Pane>
                                         <Tab.Pane eventKey="3">
                                                <p>¿Ha sido intervenido quirúrgicamente?</p>
                                                <Form.Group as={Row} className=" Justify-content-center text-center mt-5">
                                                    <Col>
                                                    
                                                        <Form.Check
                                                        type="radio"
                                                        label="Si"
                                                        name="formHorizontalRadios"
                                                        id="formHorizontalRadios2"
                                                        onClick={()=>{setThirdQuestion(true)}}
                                                        checked={thirdQuestion}
                                                        />
                                                    
                                                    </Col>
                                                    <Col>
                                                    <Form.Check
                                                        type="radio"
                                                        label="No"
                                                        name="formHorizontalRadios"
                                                        id="formHorizontalRadios3"
                                                        onClick={()=>{setThirdQuestion(false)}}
                                                        checked={!thirdQuestion}
                                                        />
                                                        </Col>
                                                    </Form.Group>
                                                    <Row className="text-center">
                                                        <Col>
                                                              <Button style={ButtonStyle} className="orangeButton rounded-pill py-0" onClick={()=>Question3Event()} >Continuar</Button>
                                                        </Col>
                                                    </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="4">
                                            <form noValidate onSubmit={formikWeight.handleSubmit}>
                                                <p>Indique su peso y talla actual</p>
                                                <Form.Group as={Row} className=" Justify-content-center text-center mt-5">
                                                    <Col>
                                                       <div className="group">
                                                                    <input
                                                                        type="number"
                                                                        name="weight"
                                                                        required
                                                                        value={formikWeight.values.weight}
                                                                        onChange={formikWeight.handleChange}
                                                                        className="inputBenefy"
                                                                    />
                                                                            <span style={{color: "red"}}></span>
                                                                            <span className="highlight"></span>
                                                                            <span className="bar"></span>
                                                                            <label className="labelInputBenefy">Peso (kg)</label>
                                                                            {formikWeight.errors.weight && formikWeight.touched.weight && (
                                                                                    <p className="inputwarningP">{formikWeight.errors.weight}</p>
                                                                                )}
                                                            </div> 
                                                            </Col>
                                                    <Col>
                                                            <div className="group">
                                                                    <input
                                                                        type="number"
                                                                        name="height"
                                                                        required
                                                                        value={formikWeight.values.height}
                                                                        onChange={formikWeight.handleChange}
                                                                        className="inputBenefy"
                                                                    />
                                                                            <span style={{color: "red"}}></span>
                                                                            <span className="highlight"></span>
                                                                            <span className="bar"></span>
                                                                            <label className="labelInputBenefy">Altura (cm)</label>
                                                                            {formikWeight.errors.height && formikWeight.touched.height && (
                                                                                    <p className="inputwarningP">{formikWeight.errors.height}</p>
                                                                                )}
                                                            </div>                      
                                                    </Col>
                                                    </Form.Group>
                                                    <Row className="text-center">
                                                        <Col>
                                                              <Button style={ButtonStyle} className="orangeButton rounded-pill py-0" type="submit" >Continuar</Button>
                                                        </Col>
                                                    </Row>
                                                </form>
                                         </Tab.Pane>
                                         <Tab.Pane eventKey="5">
                                                <p>¿Se encuentra Ud. actualmente en perfecto estado de salud y sin ningún sintoma de enfermedad?</p>
                                                <Form.Group as={Row} className=" Justify-content-center text-center mt-5">
                                                    <Col>
                                                        <Form.Check
                                                        type="radio"
                                                        label="Si"
                                                        name="formHorizontalRadios"
                                                        id="formHorizontalRadios2"
                                                        onClick={()=>{setFiveQuestion(true)}}
                                                        checked={fiveQuestion}
                                                        />
                                                    
                                                    </Col>
                                                    <Col>
                                                    <Form.Check
                                                        type="radio"
                                                        label="No"
                                                        name="formHorizontalRadios"
                                                        id="formHorizontalRadios3"
                                                        onClick={()=>{setFiveQuestion(false)}}
                                                        checked={!fiveQuestion}
                                                        />
                                                        </Col>
                                                    </Form.Group>
                                                    <Row className="text-center">
                                                        <Col>
                                                              <Button style={ButtonStyle} className="orangeButton rounded-pill py-0" onClick={()=>Question5Event()}  >Continuar</Button>
                                                        </Col>
                                                    </Row>
                                         </Tab.Pane>
                                        </Tab.Content>
                                        }
                                        
                                    </Col>
                            </Row>
                    </Tab.Container>
                </Col>
            </Row>
           </Container>
      )
}
