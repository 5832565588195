import React from 'react'
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { secundaryOrange,fourthGray,principalOrange } from '../../Style/Colors';
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from 'styled-components';
import { countryCurrency } from '../../helpers/countryInfo';
const ButtonCardStyle={
    width: "15rem",
    border:"none",
    fontSize:"1.8rem",
    background:secundaryOrange
}
const TitleStyle=styled.h1`
    font-size:2rem;
    font-weight: 900 !important;
`
const SubtitleText=styled.h2`
font-size:1.8rem;
color:${fourthGray};
font-weight: 100 !important;
`
const TableText=styled.h1`
color:${secundaryOrange};
    font-size:1.5rem;
    font-weight: 100 !important;
`
export const PresentInfo = ({country,title,subtitle, price,actionMethod}) => {
    const formik = useFormik({
        initialValues: {
            nameSendingUser:"",
            nameReceivingUser:"",
            dedicatory:"",
            emailReceivingUser:""
        },
        validationSchema: Yup.object({
            nameSendingUser: Yup.string()
                .min(2, "2 carácteres como minimo")
                .max(50, "50 caracteres como máximo")
                .required("El nombre de quien envía es requerido"),
            nameReceivingUser: Yup.string()
                .min(2, "2 carácteres como minimo")
                .max(50, "50 caracteres como máximo")
                .required("El nombre de quien recibe es requerido"),
            dedicatory: Yup.string()
                .min(2, "2 carácteres como minimo")
                .max(50, "50 caracteres como máximo")
                .required("La dedicatoria es requerida"),
            emailReceivingUser:Yup.string().required()
        }),
        onSubmit: values => {
            actionMethod(values);
        }
      });
    return (
       
          <Container>
              <Row>
                  <Col>
                  <TitleStyle className="text-left">{title}</TitleStyle>
                  <SubtitleText>{subtitle}</SubtitleText>
                  </Col>
                  <Col>
                  <TitleStyle className="text-right" > {`${countryCurrency(country)} ${price}`}</TitleStyle>
                  </Col>
              </Row>
              <Row className="mt-4">
                  <Col lg="7" md="7" sm="6" xs="12">
                  <p style={{fontSize:"1.2rem"}}>
                  Envía este regalo a esa persona que aprecias mucho, consulta nuestra zona de cobertura o escríbenos para mayor información.
                  
                      </p>
                  </Col>
                  <Col lg="5" md="5" sm="6" xs="12">
                  </Col>
              </Row>
              <form noValidate onSubmit={formik.handleSubmit}>
            <Row className="mt-4">
                <Col lg="6" md="6">
                <TableText className="text-left">Datos del regalo</TableText>
                <Table responsive borderless bordered>
                        <tbody>
                        <tr>
                            <td style={{width:"20%"}}>
                            <TableText className="text-left">De:</TableText>
                            </td>
                            <td>
                                       <input
                                                    type="text"
                                                    name="nameSendingUser"
                                                    required
                                                    value={formik.values.nameSendingUser}
                                                    onChange={formik.handleChange}
                                                    className="grayInput"
                                                /> 
                                                {formik.errors.nameSendingUser && formik.touched.nameSendingUser && (
                                                                <p className="inputwarningP">{formik.errors.nameSendingUser}</p>
                                                            )}
                            </td>
                            
                            </tr>
                            <tr>
                                    <td>
                                    <TableText className="text-left">Para:</TableText>
                                    </td>
                                    <td>
                                    <input
                                                            type="text"
                                                            name="nameReceivingUser"
                                                            required
                                                            value={formik.values.nameReceivingUser}
                                                            onChange={formik.handleChange}
                                                            className="grayInput"
                                                        />
                                            {formik.errors.nameReceivingUser && formik.touched.nameReceivingUser && (
                                                                        <p className="inputwarningP">{formik.errors.nameReceivingUser}</p>
                                                                    )}
                                    </td>
                            </tr>
                            <tr>
                                <td style={{width:"20%"}}>
                                <TableText className="text-left">Correo del destinatario:</TableText>
                                </td>
                                <td>
                                        <input
                                                        type="text"
                                                        name="emailReceivingUser"
                                                        required
                                                        value={formik.values.emailReceivingUser}
                                                        onChange={formik.handleChange}
                                                        className="grayInput"
                                                    /> 
                                                    {formik.errors.emailReceivingUser && formik.touched.emailReceivingUser && (
                                                                    <p className="inputwarningP">{formik.errors.emailReceivingUser}</p>
                                                                )}
                                </td>
                            </tr>
                    </tbody>
                </Table>
                   
                </Col>
                <Col>
                <TableText className="text-left">Mensaje:</TableText>
                                                <textarea
                                                    type="text"
                                                    name="dedicatory"
                                                    required
                                                    value={formik.values.dedicatory}
                                                    onChange={formik.handleChange}
                                                    className="grayInput"
                                                   style={{height:"80%"}}
                                                ></textarea>
                                                        {formik.errors.dedicatory && formik.touched.dedicatory && (
                                                                <p className="inputwarningP">{formik.errors.dedicatory}</p>
                                                            )}
                                        
                </Col>
            </Row>
            <Row className="text-right mt-5">
                <Col>
                <Button style={ButtonCardStyle} className="rounded-pill py-0 ml-4" type="submit">
                                            Enviar regalo
                                    </Button>                                      
                </Col>
            </Row>
            </form>
        </Container>
        
    )
}
